// UPK Workshop Tools
import upkPreWorkShopToolImage from "../images/upk-pre-workshop-tool.png";
import upkBudgetingImage from "../images/upk-budgeting.png";
import upkTemplateRevenuesImage from "../images/upk-template-revenues.png";
import upkPreWorkshopRevenuesImage from "../images/upk-pre-workshop-revenues.png";
// Planning Tools
import upkPlanningToolkitImage from "../images/upk-planning-toolkit.png";
import servingSchoolMealsImage from "../images/serving-school-meals-to-preschoolers.png";
import modelsOfServiceImage from "../images/models-of-service-delivery.png";
import modelsOfBlendingImage from "../images/models-of-blending.png";
import implementingUniversalTkImage from "../images/implementing-universal-tk.png";
import implementingUniversalMealsImage from "../images/implementing-universal-meals.png";
import enrollmentProjectionsImage from "../images/enrollment-projections.png";
import enhancingVisibilityImage from "../images/enhancing-visibility.png";
import cdeUpkPlanningImage from "../images/cde-upk-planning.png";
import caPadletsImage from "../images/ca-padlets.png";
import ResourceDocument from "./ResourceDocument";
import upkSlides from "../images/upkSlides.png";
import { ResourceTopic } from "../components/resource-library/ResourceFilter";

export default [
  {
    title: "UPK Pre-Workshop Tool",
    image: upkPreWorkShopToolImage,
    link: "https://drive.google.com/file/d/1KZkKX57r8zmQHLnZVnujsBbuLyvRbMHo/view?usp=share_link",
    topic: ResourceTopic.UPKWorkshopTools,
  },
  {
    title: "UPK Budgeting Worksheet",
    image: upkBudgetingImage,
    link: "https://docs.google.com/document/d/1qxZRs_JjuNfpVnbtX3HnVybD48UHE5FKU62atGmIhII/edit?usp=sharing",
    topic: ResourceTopic.UPKWorkshopTools,
  },
  {
    title: "Template Revenues and Costs Worksheet",
    image: upkTemplateRevenuesImage,
    link: "https://docs.google.com/document/d/1GRsdBxgLFo95MbcukSe5fmlXFip-pp3r1pOgFPvsFpo/edit?usp=sharing",
    topic: ResourceTopic.UPKWorkshopTools,
  },
  {
    title: "Pre-Workshop Revenues and Costs Tool",
    image: upkPreWorkshopRevenuesImage,
    link: "https://docs.google.com/document/d/1ycx71RlZNWVqVhm7nDOvWgtsMK06oM_ey0fFAXBB644/edit?usp=sharing",
    topic: ResourceTopic.UPKWorkshopTools,
  },
  {
    title: "UPK Workshop Slides ",
    image: upkSlides,
    link: "https://docs.google.com/presentation/d/1kn4eHWv3LoOUCx5wFpd5eSwfiChMxGGb/edit?usp=sharing&ouid=101848197628349628000&rtpof=true&sd=true",
    topic: ResourceTopic.UPKWorkshopTools,
  },
  {
    title: "UPK Planning Toolkit January 2022",
    image: upkPlanningToolkitImage,
    link: "https://drive.google.com/file/d/1RGCMsyyM3fWfWqOQ44BBgb1i6YKBgBYv/view?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "Serving School Meals to Preschoolers - USDA",
    image: servingSchoolMealsImage,
    link: "https://drive.google.com/file/d/17ZNCprfzH8rxPCuVxnCYT23Ft5Atj1lJ/view?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "Models of service delivery (COE PPT)",
    image: modelsOfServiceImage,
    link: "https://drive.google.com/file/d/10ieDJroLRBKivo7UJIN-wSWAEGKRQdEF/view?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "Models for Blending Programs to Offer Extended Learning and Care",
    image: modelsOfBlendingImage,
    link: "https://docs.google.com/document/d/1siKEl84OXEt6q8pdpvL0m5q2P4wNxuXH/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title:
      "Implementing Universal TK Webinar FINAL Book 11-9-2021-School Services",
    image: implementingUniversalTkImage,
    link: "https://drive.google.com/file/d/1ikz2ZDqFtqaTi1A4p4IlDIBAIrVWpUtq/view?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "Implementing Universal Meals in UPK",
    image: implementingUniversalMealsImage,
    link: "https://docs.google.com/document/d/1eOBfNgGO2XdJdSxhaAoAi-KQupgZEJkY/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "Enrollment projections Guidance",
    image: enrollmentProjectionsImage,
    link: "https://docs.google.com/document/d/1YSb31MXFj1lUaXqv8VH3fy8DKjnHlS2INuXA4kojS4c/edit?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title:
      "Enhancing the Visibility of Early Education in California School Districts 2.0 (2022) (1)",
    image: enhancingVisibilityImage,
    link: "https://drive.google.com/file/d/1Z_ktOa61ahCtFNyqD09tH4nj3ImowC2q/view?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "CDE UPK Planning Template",
    image: cdeUpkPlanningImage,
    link: "https://docs.google.com/document/d/1olcrXS4JKYrGyxeGnyQavNPoHHnnT_Bo/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.PlanningTools,
  },
  {
    title: "CA Padlets 2023",
    image: caPadletsImage,
    link: "https://docs.google.com/document/d/1LijENqOixlqum1tItjTcuooubR8WpSa_kWWimL8nfHQ/edit?usp=sharing",
    topic: ResourceTopic.PlanningTools,
  },
] as ResourceDocument[];
