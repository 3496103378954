import React from "react";
import Document from "./Document";
import { PDFDownloadLink } from "@react-pdf/renderer";
export interface PdfDownloadButtonProps {}
export default function PdfDownloadButton(props: PdfDownloadButtonProps) {
  return (
    <div className="question-submit-button" style={{ marginRight: 10 }}>
      <PDFDownloadLink fileName="upk-solutions.pdf" document={<Document />}>
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download PDF"
        }
      </PDFDownloadLink>
    </div>
  );
}
