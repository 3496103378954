import background from "./images/background-1.png";
import "./Background.css";

export default function Background() {
  return (
    <div
      className="background-container"
      style={{
        backgroundPosition: "0px 50px",
        backgroundImage: `url(${background})`,
      }}
    ></div>
  );
}
