import React, { useCallback } from "react";
import "./App.css";
import Background from "./Background";
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./components/misc/ScrollToTop";

function App() {
  return (
    <div className="app">
      <ScrollToTop />
      <div className="app-content-body-bar-left"></div>
      <div className="app-content-body-bar-outer"></div>
      <div className="app-content-body-bar-right"></div>
      <div className="app-content-body-bar-inner"></div>
      <Background />
      <div className="main-app-container">
        <Header />

        <Body>
          <Outlet />
        </Body>
      </div>
    </div>
  );
}

export default App;
