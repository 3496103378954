import React, { useCallback, useMemo } from "react";
import { ResourceCategory, ResourceTopic } from "./ResourceFilter";
import ResourceTopicButton from "./ResourceTopicButton";
import "./ResourceTopicSelector.css";

export interface ResourceTopicSelectorProps {
  filteredTopic: ResourceTopic;
  filteredCategory: ResourceCategory;
  onSelectTopic: (topic: ResourceTopic) => void;
}

export type CategoryToTopicMap = {
  [key in ResourceCategory]: ResourceTopic[];
};

const categoryToTopicMap: CategoryToTopicMap = {
  [ResourceCategory.All]: [
    ResourceTopic.UPKWorkshopTools,
    ResourceTopic.PlanningTools,
    ResourceTopic.DefiningAndUnderstandingUPK,
    ResourceTopic.CrosswalksLawsAndRegulations,
    ResourceTopic.CommunicatingWithParents,
    ResourceTopic.CollaboratingWithCommunityBasedProviders,
    ResourceTopic.ProfessionalDevelopment,
    ResourceTopic.P3Alignment,
    ResourceTopic.ImplementingInclusivePrograms,
    ResourceTopic.Videos,
    ResourceTopic.FundingOpportunities,
    ResourceTopic.FacilitiesAndLearningEnvironments,
  ],
  [ResourceCategory.ToolPlanning]: [
    ResourceTopic.UPKWorkshopTools,
    ResourceTopic.PlanningTools,
  ],
  [ResourceCategory.Definition]: [
    ResourceTopic.DefiningAndUnderstandingUPK,
    ResourceTopic.CrosswalksLawsAndRegulations,
  ],
  [ResourceCategory.Communication]: [
    ResourceTopic.CommunicatingWithParents,
    ResourceTopic.CollaboratingWithCommunityBasedProviders,
  ],
  [ResourceCategory.Professional]: [
    ResourceTopic.ProfessionalDevelopment,
    ResourceTopic.P3Alignment,
    ResourceTopic.ImplementingInclusivePrograms,
  ],
  [ResourceCategory.Videos]: [ResourceTopic.Videos],
  [ResourceCategory.ToolWorkforce]: [
    ResourceTopic.FundingOpportunities,
    ResourceTopic.FacilitiesAndLearningEnvironments,
  ],
};

const allTopics: ResourceTopic[] = [];

export default function ResourceTopicSelector(
  props: ResourceTopicSelectorProps
) {
  const { filteredTopic, filteredCategory, onSelectTopic } = props;

  const topics = useMemo<ResourceTopic[]>(() => {
    if (filteredTopic != ResourceTopic.All) return [filteredTopic];
    else return categoryToTopicMap[filteredCategory];
  }, [filteredTopic, filteredCategory]);

  const renderTopic = useCallback(
    (topic: ResourceTopic) => (
      <ResourceTopicButton key={topic} topic={topic} onClick={onSelectTopic} />
    ),
    [onSelectTopic]
  );

  return <div className="resource-topic-grid">{topics.map(renderTopic)}</div>;
}
