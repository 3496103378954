import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import sccoeLogo from "../images/SCCOE.jpg";
import cceeLogo from "../images/CCEE.png";
import ccsesaLogo from "../images/CCSESA.png";
import cdeLogo from "../images/CDE.jpg";
import vcoeLogo from "../images/VCOE.png";
import scoeLogo from "../images/SCOE.png";
import tcoeLogo from "../images/TCOE.png";
import ToolLinkButton from "../components/misc/ToolLinkButton";

export default function Main() {
  const navigate = useNavigate();

  const onClickUpkTool = useCallback(() => {
    console.log("Start");
    navigate("/questions");
  }, []);

  const onClickResourceLibrary = useCallback(() => {
    console.log("Start");
    navigate("/resources");
  }, []);

  return (
    <div className="main-container">
      <div className="main-spacer"></div>
      <div className="main-footer">
        <div className="main-footer-center">
          <div className="main-footer-buttons">
            <ToolLinkButton
              style={{ marginRight: 100 }}
              title={"UPK TA TOOL"}
              body={
                "Use the TA tool to generate uniquely tailored blending, braiding, and layering options for every LEA."
              }
              color={"blue"}
              onClick={onClickUpkTool}
            />
            <ToolLinkButton
              title={"RESOURCE LIBRARY"}
              body={
                "Explore the curated library to discover resources to help LEAs blend, braid, and layer programs."
              }
              color={"green"}
              onClick={onClickResourceLibrary}
            />
          </div>
        </div>
        <div className="main-footer-bottom"></div>
      </div>
      <div className="main-footer-top"></div>
      <div className="main-content-body">
        <div className="main-content-body-bar-left"></div>
        <div className="main-content-body-bar-outer"></div>
        <div className="main-content-body-bar-right"></div>
        <div className="main-content-body-bar-inner"></div>
        <div className="main-collab-title">A COLLABORATION OF</div>
        <div className="main-collab-logos-top">
          <div style={{ marginRight: 20 }} className="main-collab-big-logo">
            <img src={cceeLogo} />
          </div>
          <div className="main-collab-big-logo">
            <img src={sccoeLogo} />
          </div>
        </div>
        <div className="main-collab-logos-bottom">
          <div style={{ marginBottom: 40 }} className="main-collab-title">
            IN PARTNERSHIP WITH
          </div>
          <div className={"main-collab-logos-bottom-row"}>
            <img src={ccsesaLogo} />
            <img src={vcoeLogo} />
            <img src={scoeLogo} />

            <img src={tcoeLogo} style={{ height: 110 }} />
          </div>
        </div>
      </div>
    </div>
  );
}
