import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import useAnswerState from "../../state/useAnswerState";
import { ReactNode } from "react";
import React from "react";

Font.register({
  family: "Open Sans",
  src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf",
});

Font.register({
  family: "Open Sans",
  src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf",
  fontWeight: "700",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    backgroundColor: "white",
    paddingBottom: 30,
    paddingTop: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  sectionHeader: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    flexGrow: 1,
    color: "white",
    fontWeight: 700,
    width: "100%",
    display: "flex",
    justifyContent: "center",

    fontFamily: "Open Sans",
  },

  p: {
    marginTop: 5,
    fontSize: 12,
    fontFamily: "Open Sans",
  },
  ul: {
    margin: 10,
    fontFamily: "Open Sans",
  },
  ol: {
    margin: 10,
    fontFamily: "Open Sans",
  },
  li: {
    marginTop: 5,
    fontSize: 12,
    fontFamily: "Open Sans",
  },
  link: {
    fontFamily: "Open Sans",
  },
  h3: {
    fontFamily: "Open Sans",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "bold",
    fontSize: 15,
  },
  listContainer: {
    flexDirection: "column",
    width: 400,
  },
  listRow: {
    flexDirection: "row",
    marginBottom: 4,
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: 12,
  },
});
interface ListProps {
  items: (string | ReactNode)[];
  type: ListType;
}
enum ListType {
  Ordered = 0,
  Unordered = 1,
}
const List = (props: ListProps) => {
  const { items, type } = props;
  return (
    <View style={styles.listContainer}>
      {items.map((item, index) => {
        if (React.isValidElement(item)) {
          return (
            <View style={styles.listRow}>
              <Text style={{ ...styles.li, ...{ marginHorizontal: 8 } }}>
                {type === ListType.Ordered ? `${index + 1}.` : `•`}
              </Text>
              {item}
            </View>
          );
        } else {
          return (
            <View style={styles.listRow}>
              <Text style={{ ...styles.li, ...{ marginHorizontal: 8 } }}>
                {type === ListType.Ordered ? `${index + 1}.` : `•`}
              </Text>
              <Text style={styles.li}>{item}</Text>
            </View>
          );
        }
      })}
    </View>
  );
};

const MyDocument = () => {
  const {
    q1AnswerIndexes,
    q2AnswerIndexes,
    q3AnswerIndexes,
    q4AnswerIndexes,
    q1Answer,
    q2Answer,
    q3Answer,
    q4Answer,
    q4Sections,
    isInRange,
    hasStaffing,
    hasFacilities,
    hasCost,
    hasEnrollment,
    hasAtLeastOneSection,
  } = useAnswerState();

  const staffingOpen = true;
  const facilitiesOpen = true;
  const costOpen = true;
  const enrollmentOpen = true;

  return (
    <Document style={{ margin: 10 }}>
      <Page size="EXECUTIVE" style={styles.page}>
        <View style={styles.section}>
          <View style={{ border: "1px solid gray", padding: 10 }}>
            <Text style={styles.p}>
              You work for a {q1Answer} LEA with {q2Answer} who operates{" "}
              {q3Answer} and is struggling with {q4Answer}.
            </Text>
            {hasAtLeastOneSection && (
              <>
                <Text style={styles.p}>
                  The following are uniquely tailored options and suggestions to
                  consider based on your LEA's characteristics.
                </Text>
                <Text style={styles.p}>
                  Please note that the information provided in this tool does
                  not constitute legal advice or interpretation of statute. LEAs
                  should always consult with their own legal counsel and/or the
                  appropriate state agency to ensure that they are complying
                  with current law, regulation, and guidance.
                </Text>
              </>
            )}
          </View>
          {!hasAtLeastOneSection && (
            <>
              <Text style={styles.p}>
                Select answers to Question 4 on the previous page to see more
                recommended solutions.
              </Text>
            </>
          )}

          {hasStaffing && (
            <>
              <View
                style={{
                  ...styles.sectionHeader,
                  ...{ backgroundColor: "#af78e3" },
                }}
              >
                <Text>Staffing</Text>
              </View>
              {staffingOpen && (
                <>
                  {q4AnswerIndexes.includes(0) && (
                    <>
                      <Text style={styles.p}>
                        The state has adopted several credentialing
                        flexibilities that can help LEAs staff classrooms as TK
                        expansion is implemented, including:
                      </Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • TK Local Assignment Flexibility: In the 2022 Budget
                          Act, the state created a new TK local assignment
                          flexibility that allows the CTC to issue one year
                          emergency specialist teaching permits for TK teachers
                          if they meet the following criteria: (1) have a BA,
                          and (2) have 24 units in ECE OR have a BA in child
                          development or early childhood education OR has 3 or
                          more years teaching TK or preschool. To learn more
                          about this, flexibility, review{" "}
                          <Link
                            src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=44300.&lawCode=EDC"
                            style={styles.link}
                          >
                            Education Code 44300(j).
                          </Link>
                        </Text>
                        <Text style={styles.li}>
                          • CDP Teacher Temporary Credentials: Individuals
                          currently working as a preschool or Head Start teacher
                          can be assigned as a lead TK teacher when all of the
                          following conditions are met:
                        </Text>
                        <List
                          items={[
                            "The individual holds a Teacher Child Development Permit (CDP) or has 24 units in early childhood development.",
                            "The individual holds a BA.",
                            "The individual meets basic skills requirements",
                            "The LEA applies to the CTC to obtain a PIP or Short-term Staff Permit (STSP)",
                          ]}
                          type={ListType.Ordered}
                        />
                        <View style={styles.p}>
                          <Text>
                            To learn more about the PIP and STSP options, review
                            California Code of Regulations Title 5 or visit CTC:
                          </Text>
                          <Link
                            src={
                              "https://www.ctc.ca.gov/credentials/leaflets/short-term-staff-permit-(cl-858)"
                            }
                            style={styles.link}
                          >
                            https://www.ctc.ca.gov/credentials/leaflets/short-term-staff-permit-(cl-858)
                          </Link>
                        </View>
                        <View style={{ marginBottom: 10 }} />
                        <Text style={styles.li}>
                          • "Comparable" units flexibility: Starting in 2023,
                          individuals with a multiple subject credential must
                          meet one of the following additional requirements to
                          act as the lead TK teacher in a classroom:
                        </Text>
                        <List
                          items={[
                            "Have 24 units in ECE or early childhood ed.",
                            "As determined by the LEA, have professional experience in a classroom setting with preschool age children that is comparable to the 24 units.",
                            "Have a CDP teacher permit or early childhood specialist credential.",
                          ]}
                          type={ListType.Ordered}
                        />
                        <Text style={styles.p}>
                          An LEA that is struggling to hire enough TK teachers
                          could consider utilizing the comparable units
                          flexibility to allow a multiple subject teacher to
                          meet the qualifications. LEAs utilizing this option
                          should establish a policy defining what experience it
                          will consider to be "comparable" to the 24 units.
                        </Text>
                        {q1AnswerIndexes.includes(0) && (
                          <Text style={styles.li}>
                            • Low ADA Flexibility: LEAs with 40 or fewer
                            students enrolled in TK have the statutory
                            flexibility to utilize the same TK teacher as the
                            lead teacher in two TK AM/PM classes. In very small
                            LEAs like yours, this can be a strategic solution
                            when the LEA is unable to find enough qualified TK
                            lead teachers or does not receive sufficient funding
                            to pay for two TK lead teachers. To learn more about
                            this flexibility, review Education Codes 46118 and
                            46119.
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(1) && (
                    <>
                      <Text style={styles.p}>
                        To meet the new TK ratio requirement of 1 adult to 12
                        students, your LEA should consider implementing
                        short-term and long-term strategic recruitment and
                        retention strategies. These may include:
                      </Text>
                      <View style={styles.ul}>
                        <View style={styles.li}>
                          <Text>
                            • Revise the district's
                            paraprofessional/instructional aide requirements.
                            Currently, California law only requires that the
                            second adult in a TK classroom meet the district's
                            minimum requirements for instructional aides. Most
                            LEAs have substantial discretion in defining these
                            requirements. Your LEA may want to review and revise
                            your district's requirements for instructional aides
                            to enlarge the pool of candidates who are eligible
                            to serve as the second adult in a TK classroom.
                          </Text>
                          <View style={styles.ul}>
                            <View style={styles.li}>
                              <Text>
                                Important note: Title I targeted assistance and
                                schoolwide eligibility schools are subject to
                                additional requirements for instructional
                                aides/paraprofessionals. To learn more about
                                requirements for Title I schools, visit:{" "}
                                <Link
                                  src={
                                    "https://www.cde.ca.gov/nclb/sr/tq/paraprofessionals.asp"
                                  }
                                  style={styles.link}
                                >
                                  https://www.cde.ca.gov/nclb/sr/tq/paraprofessionals.asp
                                </Link>
                              </Text>
                            </View>
                          </View>
                        </View>
                        <Text style={styles.li}>
                          • Recruit experienced childcare and early learning
                          staff to serve as the second adult in the TK
                          classroom. Individuals with a Teacher or Associate
                          Teacher Child Development Permit (CDP) have
                          specialized training and experience working with
                          TK-age children and likely meet your LEA's minimum
                          requirements for instructional
                          aides/paraprofessionals. To learn more about the
                          qualifications of those with a Child Development
                          Permit, visit CTC's website at{" "}
                          <Link
                            src="https://www.ctc.ca.gov/credentials/leaflets/child-development-permits-(cl-797)"
                            style={styles.link}
                          >
                            https://www.ctc.ca.gov/credentials/leaflets/child-development-permits-(cl-797)
                          </Link>
                          . LEAs who pursue this option may need to revise their
                          job descriptions and policies on instructional
                          aides/paraprofessionals to ensure that those holding a
                          CDP permit are eligible.
                        </Text>
                        <Text style={styles.li}>
                          • Utilize additional TK funding to increase wages. The
                          state has allocated $2,813 per TK ADA to reduce ratios
                          in TK classrooms. This amounts to $67,512 for a full
                          classroom of 24 TK children. LEAs may utilize all or
                          some of this funding to offer competitive wages for
                          the second adult in a TK classroom.
                        </Text>
                        <Text style={styles.li}>
                          • Establish a workforce pipeline. Join with the COE,
                          other LEAs, and community-based providers to create a
                          countywide UPK workforce pipeline for the purpose of
                          recruiting, retaining, and elevating UPK staff. Field
                          surveys indicate that candidates who are considering a
                          career working with young children are often dissuaded
                          because there is no clear career pathway and they
                          struggle to identify financial assistance/grant
                          programs. Candidates may receive financial assistance
                          to enter the field but leave when they realize that
                          they need to earn additional credits to move to the
                          next step in the lattice. Establishing a UPK workforce
                          pipeline with financial assistance and incentives for
                          candidates is a long-term strategy for creating a
                          stable, competitive UPK staffing pool. To learn more
                          about strategies for creating a workforce pipeline,
                          review our resource hub folder on{" "}
                          <Link
                            src="https://drive.google.com/drive/folders/1RRE6mCTME7OXI7mvJ8vH77Fh09QT-ggY"
                            style={styles.link}
                          >
                            https://drive.google.com/drive/folders/1RRE6mCTME7OXI7mvJ8vH77Fh09QT-ggY
                          </Link>
                        </Text>
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(2) && (
                    <>
                      <Text style={styles.p}>
                        Unlike TK, LEAs are permitted to contract with
                        community-based providers to operate the LEA's Expanded
                        Learning Opportunities Program (ELO-P) and After School
                        Education and Safety (ASES) program. LEAs struggling
                        with ELO-P and/or ASES staffing should consider:
                      </Text>
                      <View style={styles.ul}>
                        <View style={styles.li}>
                          <Text>
                            • Partnering with community-based expanded learning
                            or after school providers to operate your program.
                            Contracting with a community-based provider to
                            operate your ELO-P and/or ASES program is an
                            effective strategy for containing costs and
                            liabilities. While there are pros and cons to
                            contracting with a community-based provider, the two
                            primary advantages are:
                          </Text>
                          <View style={styles.ol}>
                            <Text style={styles.li}>
                              1. community-based afterschool and expanded
                              learning providers already have the
                              infrastructure, facilities, and staff necessary to
                              operate ELO-P and ASES programs and can increase
                              their capacity more quickly than most LEAs
                            </Text>
                            <Text style={styles.li}>
                              2. contracting reduces an LEA's long-term pension
                              and benefits liabilities. Important caveat: New
                              state law requires that a school provide
                              transportation when a UPP student attending a
                              school site that is not operating ELO-P enrolls to
                              attend ELO-P at another school site. See{" "}
                              <Link
                                src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                                style={styles.link}
                              >
                                Education Code 46120(e)(6)
                              </Link>{" "}
                              to learn more about this requirement.
                            </Text>
                          </View>
                        </View>
                        <Text style={styles.li}>
                          • Contracting with community-based childcare or
                          preschool providers to operate your ELO-P and/or ASES
                          program. Childcare and preschool providers already
                          meet vigorous health and safety standards and are
                          specially trained and licensed to work with children
                          ages 0 to 12. Nearly all childcare and preschool
                          providers can easily meet ELO-P and ASES standards. In
                          addition, many childcare and preschool providers are
                          looking for new partnerships and revenue sources due
                          to declining enrollment caused by TK expansion.
                        </Text>
                        <Text style={styles.li}>
                          • Operating at fewer school sites. Although LEAs are
                          required to provide ELO-P access to all eligible
                          students, they are not required to operate ELO-P at
                          every campus in their district. To maximize staffing,
                          LEAs may consider consolidating ELO-P at fewer school
                          sites. Important caveat: New state law requires that a
                          school provide transportation when a UPP student
                          attending a school site that is not operating ELO-P
                          enrolls to attend ELO-P at another school site. See{" "}
                          <Link
                            src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                            style={styles.link}
                          >
                            Education Code 46120(e)(6)
                          </Link>{" "}
                          to learn more about this requirement.
                        </Text>
                        <Text style={styles.li}>
                          • Offering competitive stipends to teachers,
                          administrators, paraprofessionals, or instructional
                          aides. Existing LEA staff may be willing to staff the
                          ELO-P/ASES program if the LEA offers a competitive
                          stipend for their time. Important caveat: LEAs should
                          review their local bargaining agreements to ensure
                          that this option is permissible.
                        </Text>
                        {q3AnswerIndexes.includes(13) &&
                          q3AnswerIndexes.includes(14) && (
                            <>
                              <Text style={styles.li}>
                                • Blend ASES and ELO-P. ASES and ELO-P have
                                identical staffing and facilities requirements.
                                The only difference between the programs are
                                their enrollment priorities and ratio
                                requirements for TK-age students. LEAs who have
                                not already done so should consider blending
                                their ASES and ELO-P programs. This approach may
                                help LEAs maximize staffing and funding for the
                                programs.
                              </Text>
                            </>
                          )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(3) && (
                    <>
                      <Text style={styles.p}>
                        With TK expansion and the long-term impacts of COVID-19,
                        there are statewide staffing shortages in preschool,
                        Head Start, and childcare. Your LEA should consider
                        implementing short-term and long-term strategies to
                        address its staffing shortage.
                      </Text>
                      <Text style={styles.h3}>Long-Term</Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • Establish a workforce pipeline. Join with the COE,
                          other LEAs, and community-based providers to create a
                          countywide UPK workforce pipeline for the purpose of
                          recruiting, retaining, and elevating UPK staff. Field
                          surveys indicate that candidates who are considering a
                          childcare or teaching career are often dissuaded
                          because there is no clear career pathway or they
                          struggle to identify and navigate between financial
                          assistance/grant programs. Candidates may receive
                          financial assistance to enter the field but leave when
                          they realize that they need to earn additional credits
                          to move to the next step in the lattice. Establishing
                          a UPK workforce pipeline with financial assistance and
                          incentives for candidates is a long-term strategy for
                          creating a stable, competitive UPK staffing pool that
                          will also help build your LEA's pool of candidates for
                          TK. To learn more about strategies for creating a
                          workforce pipeline, review the resource hub folder on
                          <Link
                            src="https://drive.google.com/drive/folders/1RRE6mCTME7OXI7mvJ8vH77Fh09QT-ggY"
                            style={styles.link}
                          >
                            "Building a Workforce Pipeline."
                          </Link>
                        </Text>
                        {isInRange(q3AnswerIndexes, 2, 10) && (
                          <View style={styles.li}>
                            <Text>
                              • Ensure that the LEA's professional development
                              grants are designed to support the broadest pool
                              of candidates, including candidates seeking a
                              Teacher Child Development Permit (CDP). In the
                              last several years, the state has established many
                              professional development grants and funding
                              streams that can be used to support recruitment
                              and education of UPK staff. Of these, two
                              specifically can be utilized to provide help
                              candidates earn a CDP permit:
                            </Text>
                            <List
                              items={[
                                "The Classified School Employee Teacher Credentialing Program.",
                                "The Early Education Teacher Development Grant",
                              ]}
                              type={ListType.Ordered}
                            />
                            <Text style={styles.p}>
                              In addition, the UPK Planning and Implementation
                              Grant provides flexible dollars that can be used
                              to help candidates earn qualifications required to
                              teach in preschool and Head Start.
                            </Text>
                            <Text style={styles.p}>
                              If your LEA has these grants/allocations and they
                              are not currently being utilized to address the
                              LEA's preschool or Head Start staffing shortage,
                              the LEA should consider how they could be modified
                              to do so. To learn more about these
                              grants/allocations, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1eLuyJNq7C5SVt4DEDCUMRO-t_dJKlz6n"
                                style={styles.link}
                              >
                                "Funding Opportunities"
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          </View>
                        )}
                      </View>
                      <Text style={styles.h3}>Short-Term</Text>
                      <View style={styles.ul}>
                        <View style={styles.li}>
                          <Text>
                            • Partner with community-based preschool, Head
                            Start, and/or childcare providers. Rather than
                            cutting or ending its preschool/Head Start/childcare
                            programs, LEAs should consider subcontracting with a
                            community-based provider to operate their program.
                            While there are pros and cons to contracting with a
                            community-based provider, the two primary advantages
                            are:
                          </Text>
                          <List
                            items={[
                              "Community-based providers may already have the infrastructure, facilities, and staff to operate these programs and can generally increase their capacity more quickly than LEAs, and",
                              "Contracting reduces an LEA's long-term pension and benefits liabilities.",
                            ]}
                            type={ListType.Ordered}
                          />
                          <Text style={styles.p}>
                            An LEA can contract with a community-based provider
                            to operate their program while still maintaining the
                            programs location on the school campus.
                          </Text>
                        </View>
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <View style={styles.li}>
                              <Text>
                                • Blend or braid TK with your preschool program.
                                To learn more about blending and braiding,
                                review the{" "}
                                <Link
                                  src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur"
                                  style={styles.link}
                                >
                                  "Best Practices"
                                </Link>{" "}
                                folder in the resource hub. Both blending and
                                braiding are effective ways to maximize staff
                                and funding. To make it easier for LEAs to
                                blend/braid TK and preschool, CDE encourages
                                LEAs to seek a Title 22 licensing exemption. To
                                qualify, an LEA must meet the following
                                requirements:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The program is operated on a school campus
                                  that meets Title 24,Field Act, and TK/K
                                  classroom requirements{" "}
                                </Text>
                                <Text style={styles.li}>
                                  2. Only 4 year olds are served in the
                                  blended/braided program
                                </Text>
                                <Text style={styles.li}>
                                  3. The blended/braided program meets the
                                  preschool ratio requirement.
                                </Text>
                              </View>
                              <View style={styles.p}>
                                <Text>
                                  All qualified LEAs who have applied have been
                                  granted the exemption. To seek an exemption,
                                  LEAs should reach out to their CSPP contract
                                  consultant. To learn more about the LEA
                                  licensing exemption, view
                                  <Link
                                    src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1596.792.&lawCode=HSC"
                                    style={styles.link}
                                  >
                                    Health and Safety Code 1596.792
                                  </Link>
                                </Text>
                              </View>
                            </View>
                          )}
                        {(q3AnswerIndexes.includes(0) ||
                          q3AnswerIndexes.includes(1)) &&
                          q3AnswerIndexes.includes(2) &&
                          (q2AnswerIndexes.includes(3) ||
                            q2AnswerIndexes.includes(4)) && (
                            <Text style={styles.li}>
                              • Because your LEA has 80%+ UPP, it may also be
                              eligible for preschool neighborhood eligibility.
                              All 3 and 4 year old children within the
                              attendance boundaries of a school with 80%+ FRPM
                              are eligible for state preschool under a provision
                              called "neighborhood eligibility." Rules around
                              priority enrollment must still be followed, but
                              neighborhood eligibility allows preschool
                              providers to serve all students regardless of
                              income. (See{" "}
                              <Link
                                src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=8217.&lawCode=EDC"
                                style={styles.link}
                              >
                                Ed Code 8217
                              </Link>
                              .) Utilizing neighborhood eligibility can reduce
                              administrative burden and ensure that the LEA is
                              maximizing revenue sources.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(5) && (
                            <Text style={styles.li}>
                              • Blend or layer TK with your special education
                              preschool program. Only 36% of preschool-age
                              students with a disability are currently attending
                              a general education program despite the fact that
                              early education inclusion has been proven to
                              reduce the prevalence of disabilities, improve
                              learning and social emotional development, and
                              reduce special education costs in the long-term.
                              Blending or layering TK and special education
                              preschool can increase staff capacity by
                              consolidating programs in one classroom and is
                              better for children of all abilities. For example,
                              instead of operating a stand-alone special
                              education preschool program with 8 children and 2
                              adults and a stand-alone TK classroom with 16 kids
                              and 2 adults, an LEA could operate a blended
                              TK/special education preschool with 3 adults and a
                              1:8 ratio. Because special education preschool may
                              be exempt from Title 22 licensing, it is also one
                              of the easiest programs to blend/braid with TK. To
                              learn more about blending and braiding, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur"
                                style={styles.link}
                              >
                                "Best Practices"
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          (isInRange(q3AnswerIndexes, 3, 4) ||
                            isInRange(q3AnswerIndexes, 6, 10) ||
                            isInRange(q3AnswerIndexes, 15, 17)) && (
                            <Text style={styles.li}>
                              • Blend or braid TK with your early learning, Head
                              Start, or other childcare program(s). To learn
                              more about blending and braiding, visit the “Best
                              Practices” folder in the resource hub. Both
                              blending and braiding are effective ways to
                              maximize staff and funding.{" "}
                            </Text>
                          )}
                        {(q1AnswerIndexes.includes(0) ||
                          q1AnswerIndexes.includes(1)) && (
                          <Text style={styles.li}>
                            • Most small LEAs struggle to fill an entire TK
                            classroom or an entire preschool classroom. When
                            classrooms are under enrolled, it is very difficult
                            for revenue to exceed or match costs. Small LEAs
                            like yours should consider blending their TK program
                            with other programs that serve 3 and 4 year old
                            children. For example, instead of operating a
                            stand-alone TK classroom with 14 kids and 2 adults
                            and a stand-alone preschool classroom with 10 kids
                            and 2 adults, the LEA could operate a TK/preschool
                            blended classroom with 24 children and 3 adults.
                            This maximizes staff by reducing the number of total
                            adults needed from 4 to 3. To see illustrated
                            examples of blending and braiding, visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur"
                              style={styles.link}
                            >
                              "Best Practices"
                            </Link>{" "}
                            folder in the resource hub
                          </Text>
                        )}
                        {(q1AnswerIndexes.includes(2) ||
                          q1AnswerIndexes.includes(3)) && (
                          <Text style={styles.li}>
                            • Medium and large LEAs have many options for
                            blending and braiding programs. For example, an LEA
                            could operate an AM/PM braided TK and preschool
                            program in which classroom 1 operates TK in the AM
                            and preschool in the PM and classroom 2 does the
                            opposite. This approach allows LEAs to utilize 5
                            staff members rather than 6 or 7 and the funding
                            generated allows LEAs to pay more competitive
                            salaries. To see illustrated examples of blending
                            and braiding, visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur"
                              style={styles.link}
                            >
                              "Best Practices"
                            </Link>{" "}
                            folder in the resource hub
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {hasFacilities && (
            <>
              <View
                style={{
                  ...styles.sectionHeader,
                  ...{ backgroundColor: "#789ce3" },
                }}
              >
                <Text>Facilities</Text>
              </View>
              {facilitiesOpen && (
                <>
                  {q4AnswerIndexes.includes(4) && (
                    <>
                      <Text style={styles.p}>
                        With the rapid expansion of TK to all 4 year olds by
                        2025/26, many LEAs are struggling to identify facilities
                        that meet TK requirements. To learn more about TK
                        facilities requirements and to access facilities
                        planning tools, visit the{" "}
                        <Link
                          src="https://drive.google.com/drive/folders/1cVUzs8kffWA9UtRTcjbyLWuRAvGvmrHW?usp=share_link"
                          style={styles.link}
                        >
                          "Facilities"
                        </Link>{" "}
                        folder in the resource hub. In addition, your LEA should
                        consider the following flexibilities and strategies to
                        address your facilities needs:
                      </Text>
                      <Text style={styles.h3}>Flexibilities</Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • Facilities built and utilized for kindergarten prior
                          to 2000:{" "}
                          <Link
                            src="https://www.cde.ca.gov/ls/fa/sf/title5regs.asp"
                            style={styles.link}
                          >
                            Title 5 Sections 14001 to 14036
                          </Link>{" "}
                          outline the minimum requirements for school facilities
                          built after 2000. This includes the requirement that
                          new K and TK facilities are a minimum of 1,350 square
                          feet and have restrooms that are self-contained within
                          the classroom or within the kindergarten complex.
                          However, these requirements do not apply to facilities
                          that were built and utilized for kindergarten prior to
                          2000.
                        </Text>
                        <Text style={styles.li}>
                          • Exemptions:{" "}
                          <Link
                            src="https://www.cde.ca.gov/ls/fa/sf/title5regs.asp"
                            style={styles.link}
                          >
                            Title 5 Section 14030(r)
                          </Link>{" "}
                          allows an LEA to request an exemption to facilities
                          requirements "if the district can demonstrate that the
                          educational appropriateness and safety of a school
                          design would not be compromised by an alternative to
                          that standard."
                        </Text>
                      </View>
                      <Text style={styles.h3}>Short-term Strategies</Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • Consolidate TK sites: LEAs are required to offer TK
                          to all age-eligible pupils who enroll; however, LEAs
                          are not currently required to offer TK at every
                          elementary school site. Offering TK at all
                          neighborhood schools is a best practice for addressing
                          declining enrollment, but for LEAs with insufficient
                          facilities it may not be practicable. Rather than
                          operating TK at all elementary school sites, your LEA
                          may consider operating TK at fewer sites. When
                          choosing where to consolidate TK, LEAs should
                          consider:
                        </Text>
                        <List
                          items={[
                            "Availability of facilities",
                            "Concentration of TK-age students in the neighborhood",
                            "Proximity to areas where parents work",
                            "Availability of transportation.",
                          ]}
                          type={ListType.Ordered}
                        />
                        <Text style={styles.li}>
                          • Operate AM/PM programs: LEAs must operate TK for a
                          minimum of 3 hours a day and 36,000 minutes per year
                          (or 3.3 hours per day). LEAs that are short on
                          facilities meeting TK standards may consider operating
                          two part-day AM/PM TK classes in the same classroom.
                          This configuration allows the LEA to maximize space by
                          serving up to 48 total pupils in one classroom during
                          two different time spans (ie 24 in the AM; another 24
                          in the PM). Before adopting this strategy LEAs should
                          consider how it will impact enrollment. Approximately
                          85% of parents say that they need full-day (9+ hours)
                          options for their children and many LEAs operating
                          part-day TK are struggling with under-enrollment. In
                          addition, LEAs should consider how operation of
                          part-day TK will impact ELO-P. ELO-P requires LEAs to
                          offer and provide 9 hours of combined instructional
                          time, recess, meals, and expanded learning
                          opportunities to all UPP students. For example, an LEA
                          that operates a 3.3 hour TK program will need to
                          provide (and fund) TK UPP students with another 5.7
                          hours of expanded learning opportunities utilizing
                          ELO-P or another funding source.
                        </Text>
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(5) && (
                            <Text style={styles.li}>
                              • Blend or layer TK with your special education
                              preschool program. Only 36% of preschool-age
                              students with a disability are currently attending
                              a general education program despite the fact that
                              early education inclusion has been proven to
                              reduce the prevalence of disabilities, improve
                              learning and social emotional development, and
                              reduce special education costs in the long-term.
                              Blending or layering TK and special education
                              preschool can increase staff capacity by
                              consolidating programs in one classroom and is
                              better for children of all abilities. For example,
                              instead of operating a stand-alone special
                              education preschool program with 8 children and 2
                              adults and a stand-alone TK classroom with 16 kids
                              and 2 adults, an LEA could operate a blended
                              TK/special education preschool with 3 adults and a
                              1:8 ratio. Because special education preschool may
                              be exempt from Title 22 licensing, it is also one
                              of the easiest programs to blend/braid with TK. To
                              learn more about blending and braiding, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                style={styles.link}
                              >
                                "Best Practices"
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <View style={styles.li}>
                              <Text>
                                • Blend or braid TK with your preschool program:
                                LEAs that blend or braid TK with preschool may
                                be able to double their facilities capacity. For
                                example, instead of operating a stand-alone TK
                                classroom with 12 kids and a stand-alone
                                preschool classroom with 12 kids an LEA can
                                operate a single blended TK/preschool blended
                                classroom with 24 in one classroom. This
                                increases facilities capacity by reducing the
                                number of total classrooms needed from 2 to 1.
                                To see illustrated examples of blending and
                                braiding, visit the{" "}
                                <Link
                                  src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                  style={styles.link}
                                >
                                  "Best Practices"
                                </Link>{" "}
                                folder in the resource hub. Important note: To
                                make it easier to blend/braid TK and preschool,
                                LEAs may seek a Title 22 licensing exemption. To
                                qualify, an LEA must meet the following
                                requirements:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The program is operated on a school campus
                                  that meets Title 24,Field Act, and TK/K
                                  classroom requirements
                                </Text>
                                <Text style={styles.li}>
                                  2. Only 4 year olds are served in the
                                  blended/braided program
                                </Text>
                                <Text style={styles.li}>
                                  3. The blended/braided program meets the
                                  preschool ratio requirement
                                </Text>
                              </View>
                              <View style={styles.p}>
                                <Text style={styles.p}>
                                  All qualified LEAs who have applied have been
                                  granted the exemption. To seek an exemption,
                                  LEAs should reach out to their CSPP contract
                                  consultant. To learn more about the LEA
                                  licensing exemption, view{" "}
                                  <Link
                                    src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1596.792.&lawCode=HSC"
                                    style={styles.link}
                                  >
                                    Health and Safety Code 1596.792
                                  </Link>
                                  .
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          (isInRange(q3AnswerIndexes, 3, 4) ||
                            isInRange(q3AnswerIndexes, 6, 10) ||
                            isInRange(q3AnswerIndexes, 15, 17)) && (
                            <Text style={styles.li}>
                              • Blend or braid TK with another UPK program(s):
                              LEAs that blend or braid TK with other UPK
                              programs may be able to double their facilities
                              capacity. For example, instead of operating a 6
                              hour TK program and then transporting TK students
                              to another site/location for CCTR, an LEA could
                              utilize the same classroom space for both the TK
                              and CCTR programs. This increases facilities
                              capacity by reducing the number of total
                              classrooms needed from 2 to 1. To learn more about
                              blending and braiding, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                style={styles.link}
                              >
                                Best Practices
                              </Link>{" "}
                              folder in the resource hub. Important caveat: CCTR
                              can only be used as a braided wrap for children
                              who are 5 years and older. Other programs, such as
                              CSPP, ELO-P, Head Start, AP, CalWORKS, ASES, etc,
                              can be used as a braided funding source for
                              children under the age of 5. To learn more about
                              facilities requirements for TK versus childcare
                              programs, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1cVUzs8kffWA9UtRTcjbyLWuRAvGvmrHW?usp=share_link"
                                style={styles.link}
                              >
                                "Facilities"
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          )}
                      </View>
                      <Text style={styles.h3}>Long-term Strategies</Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • Apply for state facilities funding: The state has a
                          number of facilities grants that can help LEAs build
                          or modify facilities to increase capacity for TK.
                          Visit the{" "}
                          <Link
                            src="https://drive.google.com/drive/folders/1eLuyJNq7C5SVt4DEDCUMRO-t_dJKlz6n?usp=sharing"
                            style={styles.link}
                          >
                            Funding Opportunities
                          </Link>{" "}
                          folder in the resource hub to learn more.
                        </Text>
                        <Text style={styles.li}>
                          • Run a local ballot initiative: LEAs may raise funds
                          for school facilities by placing a local initiative on
                          the ballot. Running a local ballot initiative can be
                          costly, requires collaboration with local elected
                          officials, and certain types of initiatives may
                          required a 2/3 majority vote to pass. However, local
                          school ballot initiatives have historically done well
                          in California and most that have made it on the ballot
                          have passed. To learn more about local school ballot
                          initiatives, visit the{" "}
                          <Link src="https://cashnet.org/" style={styles.link}>
                            California Coalition for Adequate School Housing
                          </Link>{" "}
                          .
                        </Text>
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(5) && (
                    <>
                      <Text style={styles.p}>
                        Unlike most other K-12 programs, ELO-P and ASES
                        facilities requirements are very flexible. ELOP/ASES do
                        not have maximum class sizes or specific facilities
                        requirements and can be held in non-classroom spaces
                        like the school cafeteria or gym.
                      </Text>
                      <Text style={styles.p}>
                        In addition, although LEAs are required to provide ELO-P
                        access to all eligible students, they are not required
                        to operate ELO-P at every campus in their district. If
                        facilities are scarce at a school site, an LEA may
                        choose not to operate ELO-P at that site and instead
                        transport eligible students from that site to another
                        school site operating ELO-P. (Important caveat: New
                        state law requires LEAs to provide transportation for a
                        student if ELO-P is not offered at that student's campus
                        and the student is attending an ELO-P program at another
                        school campus.{" "}
                        <Link
                          src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                          style={styles.link}
                        >
                          See Education Code 46120(e)(6)
                        </Link>{" "}
                        to learn more about this requirement.)
                      </Text>
                      <Text style={styles.p}>
                        Also unlike other K-12 programs, LEAs are permitted to
                        contract with community-based providers for ELOP and/or
                        ASES. Contracted ELOP/ASES providers are permitted to
                        operate program(s) at non-school sites which can help
                        free up facilities space. LEAs struggling with ELO-P
                        and/or ASES facilities capacity should consider:
                      </Text>
                      <View style={styles.ul}>
                        <View style={styles.li}>
                          <Text>
                            • Partnering with community-based expanded learning
                            or after school providers to operate ELOP/ASES off
                            campus. While there are pros and cons to contracting
                            with a community-based provider, the two primary
                            advantages are:
                          </Text>
                          <View style={styles.ol}>
                            <Text style={styles.li}>
                              1. Community-based afterschool and expanded
                              learning providers already have the
                              infrastructure, facilities, and staff to operate
                              ELO-P and ASES programs and can increase their
                              capacity more quickly than most LEAs
                            </Text>
                            <Text style={styles.li}>
                              2. Contracting reduces an LEA's long-term pension
                              and benefits liabilities.
                            </Text>
                          </View>
                        </View>
                        <Text style={styles.li}>
                          • Contracting with community-based childcare or
                          preschool providers to operate ELOP/ASES off campus.
                          Childcare and preschool providers already meet
                          vigorous health and safety standards and are specially
                          trained and licensed to work with children ages 0 to
                          12. Nearly all childcare and preschool providers can
                          easily meet ELO-P and ASES standards. In addition,
                          many childcare and preschool providers are looking for
                          new partnerships and revenue sources due to declining
                          enrollment caused by TK expansion.
                        </Text>
                        {(q3AnswerIndexes.includes(2) ||
                          q3AnswerIndexes.includes(3) ||
                          q3AnswerIndexes.includes(5) ||
                          isInRange(q3AnswerIndexes, 15, 17) ||
                          q3AnswerIndexes.includes(6) ||
                          q3AnswerIndexes.includes(10) ||
                          q3AnswerIndexes.includes(13) ||
                          q3AnswerIndexes.includes(14)) && (
                          <Text style={styles.li}>
                            • Blend ELO-P with other UPK program(s): LEAs that
                            blend ELO-P with other UPK programs may be able to
                            double their facilities capacity. For example,
                            instead of operating two separate afterschool
                            programs, one funded by ELO-P and one by CCTR, an
                            LEA could blend these two programs so long as it
                            complies with the staffing, ratio, and facilities
                            requirements of the program with higher standards.
                            Important caveat: ELO-P and CCTR funding cannot be
                            used during the school day. Therefore ELO-P and CCTR
                            cannot be blended with TK; they can only be braided.
                            To learn more about blending and braiding, visit the
                            “Best Practices” folder in the resource hub.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(14) && (
                          <Text style={styles.li}>
                            • Blend ELO-P with ASES: LEAs that blend ELO-P with
                            ASES may be able to double their facilities
                            capacity. ASES and ELO-P have the same facilities
                            and staffing requirements which makes them easy to
                            blend. For example, instead of operating two
                            separate afterschool programs, one funded by ELO-P
                            and one by ASES, an LEA could blend these programs
                            and operate a single afterschool program in a single
                            facility. Important caveat: A blended ASES/ELO-P
                            program would need to meet the ELO-P adult:ratio
                            requirement for TK/K of 1:10. To learn more about
                            blending and braiding, visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                              style={styles.link}
                            >
                              "Best Practices"
                            </Link>{" "}
                            folder in the resource hub
                          </Text>
                        )}
                        {(q3AnswerIndexes.includes(0) ||
                          q3AnswerIndexes.includes(1)) && (
                          <Text style={styles.li}>
                            • Braid with TK: LEAs that braid TK with ELO-P may
                            be able to double their facilities capacity. For
                            example, instead of operating a 6 hour TK program
                            and then transporting TK students to another
                            site/location for ELO-P, an LEA could utilize the
                            same classroom space for both the TK and ELO-P
                            programs. This increases facilities capacity by
                            reducing the number of total classrooms needed from
                            2 to 1. To learn more about blending and braiding,
                            visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                              style={styles.link}
                            >
                              Best Practices
                            </Link>{" "}
                            folder in the resource hub.
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(6) && (
                    <>
                      <Text style={styles.p}>
                        As an LEA with limited facilities capacity for
                        preschool, Head Start, or childcare, may want to
                        consider the following:
                      </Text>
                      <View style={styles.ul}>
                        <View style={styles.li}>
                          <Text>
                            • Partner with community-based provider(s) to
                            operate programs. Contracting with a community-based
                            provider to operate the LEA's preschool, Head Start,
                            or childcare program at another location can help
                            free up space on campus. While there are pros and
                            cons to contracting with a community-based provider,
                            the two primary advantages are:
                          </Text>
                          <View style={styles.ol}>
                            <Text style={styles.li}>
                              1. Community-based providers may already have the
                              infrastructure, facilities, and staff necessary to
                              operate programs and can generally increase their
                              capacity more quickly than LEAs
                            </Text>
                            <Text style={styles.li}>
                              2. Contracting reduces an LEA's long-term pension
                              and benefits liabilities
                            </Text>
                          </View>
                        </View>
                        <Text style={styles.li}>
                          • Move off campus. Most UPK programs, except TK, can
                          be operated off a school campus at a Title 22 licensed
                          facility. However, before moving a program off the
                          school campus, an LEA should consider how doing so may
                          impact enrollment. Parents prefer to have siblings
                          located at the same site and are more likely to enroll
                          their child at an elementary school if the child
                          attended preschool or childcare there first. Moving a
                          program off the school campus may have a long-term
                          impact on both that program's enrollment and the LEA's
                          K-12 enrollment.
                        </Text>
                        <Text style={styles.li}>
                          • Apply for state facilities funding: The state
                          recently added preschool to the TK and Full-Day
                          Kindergarten facilities grant which provides funding
                          to LEAs seeking to build or modify facilities to
                          increase capacity for UPK. Visit the{" "}
                          <Link
                            src="https://drive.google.com/drive/folders/1eLuyJNq7C5SVt4DEDCUMRO-t_dJKlz6n?usp=sharing"
                            style={styles.link}
                          >
                            "Funding Opportunities"
                          </Link>{" "}
                          folder in the resource hub to learn more.
                        </Text>
                        {q3AnswerIndexes.includes(2) && (
                          <Text style={styles.li}>
                            • Transition to AM/PM preschool programs: LEAs must
                            operate CSPP for a minimum of 3 hours and less than
                            4 hours a day. LEAs with insufficient preschool
                            facilities may consider operating two part-day AM/PM
                            classes in the same classroom. This configuration
                            allows the LEA to maximize space by serving up to 48
                            total pupils in one classroom during two different
                            time spans (ie 24 in the AM; another 24 in the PM).
                            Before adopting this strategy LEAs should consider
                            how it will impact enrollment. Approximately 85% of
                            parents say that they need full-day (9+ hours)
                            options for their children and many LEAs operating
                            part-day CSPP are struggling with under-enrollment.
                            Important caveat: Transitioning from a full-day to
                            part-day CSPP would require CDE's approval. Contact
                            your CDE consultant to learn more.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(5) &&
                          q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(1) &&
                          q3AnswerIndexes.includes(2) &&
                          q3AnswerIndexes.includes(3) &&
                          q3AnswerIndexes.includes(4) &&
                          q3AnswerIndexes.includes(15) &&
                          q3AnswerIndexes.includes(16) &&
                          q3AnswerIndexes.includes(17) &&
                          q3AnswerIndexes.includes(6) && (
                            <Text style={styles.li}>
                              • Blend or braid your special education preschool
                              program with another UPK program. Only 36% of
                              preschool-age students with a disability are
                              currently attending a general education program
                              despite the fact that early education inclusion
                              has been proven to reduce the prevalence of
                              disabilities, improve learning and social
                              emotional development, and reduce special
                              education costs in the long-term. Blending or
                              layering special education preschool with another
                              UPK program can increase staff capacity by
                              consolidating programs in one classroom and is
                              better for children of all abilities. For example,
                              instead of operating a stand-alone special
                              education preschool program with 8 children and 2
                              adults and a stand-alone CSPP classroom with 16
                              kids and 2 adults, an LEA could operate a blended
                              CSPP/special education preschool with 3 adults and
                              a 1:8 ratio. Because special education preschool
                              may be exempt from Title 22 licensing, it is also
                              one of the easiest programs to blend/braid with.
                              To learn more about blending and braiding, visit
                              the{" "}
                              <Link src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link">
                                "Best Practices"
                              </Link>{" "}
                              folder in the resource hub.{" "}
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <View style={styles.li}>
                              <Text>
                                • Blend or braid preschool with TK: LEAs that
                                blend or braid TK with preschool may be able to
                                double their facilities capacity. For example,
                                instead of operating a stand-alone TK classroom
                                with 12 kids and a stand-alone preschool
                                classroom with 12 kids, an LEA can operate a
                                single blended TK/preschool classroom with 24
                                kids in one classroom. This increases facilities
                                capacity by reducing the number of total
                                classrooms needed from 2 to 1. To see
                                illustrated examples of blending and braiding,
                                visit the{" "}
                                <Link
                                  src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                  style={styles.link}
                                >
                                  "Best Practices"
                                </Link>{" "}
                                folder in the resource hub. Important note: To
                                make it easier to blend/braid TK and preschool,
                                LEAs may seek a Title 22 licensing exemption. To
                                qualify, an LEA must meet the following
                                requirements:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The program is operated on a school campus
                                  that meets Title 24,Field Act, and TK/K
                                  classroom requirements
                                </Text>
                                <Text style={styles.li}>
                                  2. Only 4 year olds are served in the
                                  blended/braided program
                                </Text>
                                <Text style={styles.li}>
                                  3. The blended/braided program meets the
                                  preschool ratio requirement. All qualified
                                  LEAs who have applied have been granted the
                                  exemption. To seek an exemption, LEAs should
                                  reach out to their CSPP contract consultant.
                                  To learn more about the LEA licensing
                                  exemption, view{" "}
                                  <Link
                                    src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1596.792.&lawCode=HSC"
                                    style={styles.link}
                                  >
                                    Health and Safety Code 1596.792
                                  </Link>
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.length >= 2 && (
                          <Text style={styles.li}>
                            • Blend or braid UPK programs. LEAs that blend or
                            braid UPK programs may be able to double their
                            facilities capacity. For example, instead of
                            operating a stand-alone preschool classroom with 10
                            kids and a stand-alone Head Start classroom with 10
                            kids, an LEA can operate a single blended
                            preschool/Head Start classroom with 20 kids in one
                            classroom. This increases facilities capacity by
                            reducing the number of total classrooms needed from
                            2 to 1. To see illustrated examples of blending and
                            braiding, visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                              style={styles.link}
                            >
                              Best Practices
                            </Link>{" "}
                            folder in the resource hub.
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(7) && (
                    <>
                      <Text style={styles.p}>
                        With facility space in high demand, some LEAs are
                        considering moving their preschool, Head Start, or other
                        childcare program off campus. Before doing so, LEAs
                        should discuss the following considerations and options:
                      </Text>
                      <Text style={styles.h3}>Considerations</Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • Parents with older children attending elementary
                          school prefer to enroll both of their children at the
                          same site. When this is not possible, parents often
                          choose the UPK provider nearest their older child’s
                          school site or consider enrolling both children in
                          another school that can serve both children’s needs.
                          Before moving your preschool, Head Start, or childcare
                          program off campus, consider how doing so may
                          significantly decrease enrollment.
                        </Text>
                        <Text style={styles.li}>
                          • Recent research demonstrated that parents who
                          enrolled their child at an LEA-operated preschool
                          program in southern California were much more likely
                          to enroll their child at that LEAs elementary school
                          when the child became eligible for TK/K. LEAs should
                          consider how moving their preschool, Head Start, or
                          childcare program off campus may contribute to
                          declining enrollment and increase the likelihood that
                          parents will choose to enroll their child in a private
                          or non-traditional K-12 school.
                        </Text>
                      </View>
                      <Text style={styles.h3}>Options</Text>
                      <View style={styles.ul}>
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <View style={styles.li}>
                              <Text>
                                • Blend or braid preschool with TK: LEAs that
                                blend or braid TK with preschool may be able to
                                double their facilities capacity. For example,
                                instead of operating a stand-alone TK classroom
                                with 12 kids and a stand-alone preschool
                                classroom with 12 kids, an LEA can operate a
                                single blended TK/preschool classroom with 24
                                kids in one classroom. This increases facilities
                                capacity by reducing the number of total
                                classrooms needed from 2 to 1. To see
                                illustrated examples of blending and braiding,
                                visit the
                                <Link
                                  src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                  style={styles.link}
                                >
                                  "Best Practices"
                                </Link>{" "}
                                folder in the resource hub. Important note: To
                                make it easier to blend/braid TK and preschool,
                                LEAs may seek a Title 22 licensing exemption. To
                                qualify, an LEA must meet the following
                                requirements:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The program is operated on a school campus
                                  that meets Title 24,Field Act, and TK/K
                                  classroom requirements
                                </Text>
                                <Text style={styles.li}>
                                  2. Only 4 year olds are served in the
                                  blended/braided program
                                </Text>
                                <Text style={styles.li}>
                                  3. The blended/braided program meets the
                                  preschool ratio requirement. All qualified
                                  LEAs who have applied have been granted the
                                  exemption. To seek an exemption, LEAs should
                                  reach out to their CSPP contract consultant.
                                  To learn more about the LEA licensing
                                  exemption, view{" "}
                                  <Link
                                    src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1596.792.&lawCode=HSC"
                                    style={styles.link}
                                  >
                                    Health and Safety Code 1596.792
                                  </Link>
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.length >= 2 && (
                          <Text style={styles.li}>
                            • Blend or braid UPK programs. LEAs that blend or
                            braid UPK programs may be able to double their
                            facilities capacity. For example, instead of
                            operating a stand-alone preschool classroom with 10
                            kids and a stand-alone Head Start classroom with 10
                            kids, an LEA can operate a single blended
                            preschool/Head Start classroom with 20 kids in one
                            classroom. This increases facilities capacity by
                            reducing the number of total classrooms needed from
                            2 to 1. To see illustrated examples of blending and
                            braiding, visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                              style={styles.link}
                            >
                              "Best Practices"
                            </Link>{" "}
                            folder in the resource hub.
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {hasCost && (
            <>
              <View
                style={{
                  ...styles.sectionHeader,
                  ...{ backgroundColor: "#a66f5e" },
                }}
              >
                <Text>Cost</Text>
              </View>
              {costOpen && (
                <>
                  {q4AnswerIndexes.includes(8) && (
                    <>
                      <Text style={styles.p}>
                        With increasing wages, COLA, and pension contributions,
                        some LEAs are concerned about the ability to cover the
                        cost of TK expansion. As an LEA who believes TK funding
                        may not be sufficient to cover operational costs, you
                        should consider the following:
                      </Text>
                      <Text style={styles.p}>
                        TK adult:child ratio adjustment: To help cover the cost
                        of implementing the new 1:12 adult:child ration in TK,
                        every LEA receives $2,813 per TK ADA. This equates to
                        $67,512 per classroom of 24 children (assuming 100%
                        attendance). Your LEA should consider whether it is
                        currently effectively utilizing these funds and how they
                        could be strategically budgeted to help cover the cost
                        of TK.
                      </Text>
                      <Text style={styles.p}>
                        In addition, LEAs should consider:
                      </Text>
                      <View style={styles.ul}>
                        {(q1AnswerIndexes.includes(0) ||
                          q1AnswerIndexes.includes(1)) &&
                          q3AnswerIndexes.includes(0) &&
                          isInRange(q3AnswerIndexes, 2, 17) && (
                            <Text style={styles.li}>
                              • When TK classrooms are under enrolled, it is
                              very difficult for revenue to exceed or match
                              costs. Small LEAs like yours should consider
                              blending their TK program with other programs that
                              serve 3 and 4 year old children. For example,
                              instead of operating a stand-alone TK classroom
                              with 14 kids and 2 adults and a stand-alone
                              preschool classroom with 10 kids and 2 adults, the
                              LEA could operate a TK/preschool blended classroom
                              with 24 children and 3 adults. This strategy
                              lowers costs by reducing both operational costs
                              (ie 1 classroom overhead instead of 2) and the
                              number of total staff needed while still
                              maintaining the high-quality adult:child ration of
                              1:8. To see illustrated examples of blending and
                              braiding, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                style={styles.link}
                              >
                                "Best Practices"
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          )}
                        {(q1AnswerIndexes.includes(0) ||
                          q1AnswerIndexes.includes(1)) &&
                          !q3AnswerIndexes.includes(2) &&
                          !q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • When TK classrooms are under enrolled, it is
                              very difficult for revenue to exceed or match
                              costs. Small LEAs like yours can fill a classroom
                              by blending their TK program with other programs
                              that serve 3 and 4 year old children. Your LEA
                              should consider applying for state preschool
                              (CSPP)or Head Start slots. CSPP and Head Start can
                              be blended with TK to generate funds for children
                              enrolled who are not age eligible for TK but who
                              meet the income and/or need requirements of the
                              CSPP or Head Start program. To learn more about
                              the eligibility for these programs, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1Qa8TczsGlnhOlzwYkYIz3rSVbsYhtw2a?usp=share_link"
                                style={styles.link}
                              >
                                Crosswalks, Laws & Regulations
                              </Link>
                              folder in the resource hub.
                            </Text>
                          )}
                        {(q1AnswerIndexes.includes(0) ||
                          q1AnswerIndexes.includes(1)) &&
                          q3AnswerIndexes.includes(0) &&
                          !q3AnswerIndexes.includes(1) && (
                            <View style={styles.li}>
                              <Text style={styles.text}>
                                • To help cover the cost of TK, some small LEAs
                                have implemented early admittance TK to help
                                fill the classroom and generate additional
                                funds. This strategy allows the LEA to begin
                                receiving a proportionate share of TK funding
                                once an early admittance TK child turns 5. For
                                example, if the eligibility cut off for TK is
                                February 2nd and an enrolled child turns 5 on
                                March 2nd, the LEA could receive approximately
                                20% of TK ADA for that child, or about $2,000.
                                When deciding whether or not to admit TK
                                students early, LEAs should consider:
                              </Text>
                              <List
                                items={[
                                  "Will the cost of operating TK increase by adding early admittance TK students or remain the same? In other words, will the LEA have to add staff and classrooms or will the cost remain the same while the LEA is able to collect some additional funds for the early admittance TK students?",
                                  "How much will the LEA expand TK eligibility? To contain unfunded costs, LEAs may want to only expand eligibility by one or two months beyond what is statutorily required in that year. For example, if TK eligibility is September 2 to February 2, the LEA might choose to only expand eligibility to March 2 instead of all 4 year olds",
                                  <Text style={styles.li}>
                                    How will early admittance TK increase the
                                    LEA's ELO-P obligations? ELO-P requires LEAs
                                    to provide 9 hours of combined instruction
                                    and expanded learning to all enrolled UPP TK
                                    students, whether or not the student is
                                    age-eligible for TK. An LEA that admits a
                                    child to TK early will only receive a
                                    proportion of the TK funding for that child
                                    and will not receive any ELO-P funding but
                                    is still required to offer 9 hours of
                                    combined instruction and expanded learning
                                    if the child is UPP eligible. To learn more
                                    about how early admittance TK funding is
                                    calculated, visit the{" "}
                                    <Link
                                      src="https://drive.google.com/drive/folders/1uZQKIaqE7EhKbpkhecUIjUncHwgCkTQO?usp=share_link"
                                      style={styles.link}
                                    >
                                      "Planning Tools"
                                    </Link>{" "}
                                    folder in the resource hub. Important
                                    caveat: Because of the way that ADA is
                                    calculated, LEAs are unlikely to receive any
                                    funding for early admittance TK students who
                                    turn 5 after April 15.
                                  </Text>,
                                ]}
                                type={ListType.Ordered}
                              />
                            </View>
                          )}
                        {q3AnswerIndexes.includes(1) && (
                          <Text style={styles.li}>
                            • As an LEA that chose to implement early admittance
                            TK, you may now be experiencing program deficits due
                            to the number of children in your programs that are
                            generating no or little TK funding. To address this,
                            your LEA should consider blending TK with other
                            programs that serve 3 and 4 year old children. For
                            example, instead of operating a stand-alone TK
                            classroom with 14 kids and 2 adults and a
                            stand-alone preschool classroom with 10 kids and 2
                            adults, the LEA could operate a TK/preschool blended
                            classroom with 24 children and 3 adults. This
                            strategy lowers costs by reducing both operational
                            costs (ie 1 classroom overhead instead of 2) and the
                            number of total staff needed while still maintaining
                            the high-quality adult:child ration of 1:8. To see
                            illustrated examples of blending and braiding, visit
                            the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                              style={styles.link}
                            >
                              Best Practices
                            </Link>{" "}
                            folder in the resource hub.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(1) &&
                          q3AnswerIndexes.includes(2) && (
                            <Text style={styles.li}>
                              • Blending TK and CSPP. By blending with CSPP,
                              your LEA may be able to generate CSPP funding for
                              children enrolled who are not age-eligible for TK
                              yet. For example, in a classroom with 14 children
                              who are age-eligible for TK and 10 who are not,
                              the LEA may be able to enroll some of the other 10
                              children in CSPP if they meet the eligibility
                              requirements for CSPP. It should be noted that
                              CSPP age eligibility is significantly broader than
                              TK (2.9 years to 5 years old) and CSPP income
                              eligibility was recently increased to 100% of SMI
                              (or $112,105 for a family of 4). In addition, CSPP
                              enrolled children can generate additional funding
                              through adjustment factors for serving high-need
                              children such as those with exceptional needs or
                              dual language learners.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • Blending TK and Head Start. By blending with
                              Head Start, your LEA may be able to generate Head
                              Start funding for children enrolled who are not
                              age-eligible for TK yet. For example, in a
                              classroom with 14 children who are age-eligible
                              for TK and 10 who are not, the LEA may be able to
                              enroll some of the other 10 children in Head Start
                              if they meet the eligibility requirements. It
                              should be noted that Head Start income eligibility
                              was recently expanded to include families who are
                              eligible for SNAP benefits (ie approximately
                              $36,075 for a family of 4).
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(1) &&
                          !q3AnswerIndexes.includes(2) &&
                          !q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • Your LEA should consider applying for state
                              preschool (CSPP)or Head Start slots. CSPP and Head
                              Start can be blended with TK to generate funds for
                              children enrolled who are not age eligible for TK
                              but who meet the income and/or need requirements
                              of the CSPP or Head Start program. To learn more
                              about the eligibility for these programs, visit
                              the
                              <Link
                                src="https://drive.google.com/drive/folders/1Qa8TczsGlnhOlzwYkYIz3rSVbsYhtw2a?usp=share_link"
                                style={styles.link}
                              >
                                Crosswalks, Laws & Regulations
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          )}
                        {(q2AnswerIndexes.includes(2) ||
                          q2AnswerIndexes.includes(3) ||
                          q2AnswerIndexes.includes(4)) &&
                          !q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • As an LEA with high UPP, you should consider
                              applying for a Head Start grant or partnering with
                              a community based organization that operates Head
                              Start. Head Start can be blended with TK to
                              generate funds for children enrolled who are not
                              age eligible for TK but who meet the eligibility
                              requirements for Head Start program. In addition,
                              Head Start provides a relatively high rate per
                              child served, flexibility around enrollment, and
                              includes funding for grantees to provide wrap and
                              support services such as parent trainings, dental
                              screenings, and mental health consultation. To
                              learn more about the advantages of braiding or
                              blending with Head Start, visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                style={styles.link}
                              >
                                "Best Practices"
                              </Link>{" "}
                              folder in the resource hub.
                            </Text>
                          )}
                        {(q1AnswerIndexes.includes(2) ||
                          q1AnswerIndexes.includes(3)) &&
                          q3AnswerIndexes.includes(0) &&
                          isInRange(q3AnswerIndexes, 2, 17) && (
                            <Text style={styles.li}>
                              • Blending, braiding, and layering are key
                              strategies when the cost of TK exceeds revenues.
                              Medium and large LEAs like yours have more options
                              for blending and braiding especially when they
                              operate or hold contracts for multiple additional
                              UPK programs. Specifically, your LEA should
                              consider the following suggestions or visit the{" "}
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                style={styles.link}
                              >
                                Best Practices
                              </Link>{" "}
                              folder in the resource hub to learn more about
                              blend and braiding options:
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(2) && (
                          <Text style={styles.li}>
                            • Blending or braiding TK and CSPP. By blending TK
                            with CSPP, your LEA may be able to generate CSPP
                            funding for children enrolled who are not
                            age-eligible for TK yet. For example, in a classroom
                            with 14 children who are age-eligible for TK and 10
                            who are not, the LEA may be able to enroll some of
                            the other 10 children in CSPP if they meet the
                            eligibility requirements for CSPP. Alternatively or
                            additionally, your LEA could braid TK and CSPP. For
                            example, an LEA could operate an 6 hour TK program
                            in the AM and braid it with a 3 hour CSPP program in
                            the PM. It should be noted that CSPP age eligibility
                            is significantly broader than TK (2.9 years to 5
                            years old) and CSPP income eligibility was recently
                            increased to 100% of SMI (or $112,105 for a family
                            of 4), so many more families now qualify for CSPP.
                            In addition, CSPP enrolled children can generate
                            additional funding through adjustment factors for
                            serving high-need children such as those with
                            exceptional needs or dual language learners.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(3) && (
                          <Text style={styles.li}>
                            • Blending or braiding TK and Head Start. By
                            blending with Head Start, your LEA may be able to
                            generate Head Start funding for children enrolled
                            who are not age-eligible for TK yet. For example, in
                            a classroom with 10 children who are age-eligible
                            for TK and 10 who are not, the LEA may be able to
                            enroll some of the other 10 children in Head Start
                            if they meet the eligibility requirements.
                            Alternatively or additionally, your LEA could braid
                            TK and Head Start. For example, an LEA could operate
                            an 5 hour TK program in the AM and braid it with a 4
                            hour Head Start program in the PM. It should be
                            noted that Head Start income eligibility was
                            recently expanded to include families who are
                            eligible for SNAP benefits (ie approximately $36,075
                            for a family of 4)
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(4) && (
                          <Text style={styles.li}>
                            • Blending TK and Title I preschool. By blending TK
                            with Title I preschool, your LEA may be able to
                            cover the cost of instruction for children who are
                            not age-eligible for TK yet. For example, in a
                            classroom with 14 children who are age-eligible for
                            TK and 10 who are not, the LEA may be able to use
                            Title I funding to pay for the slots of children who
                            are not yet eligible for TK.
                          </Text>
                        )}
                        {!q3AnswerIndexes.includes(4) &&
                          isInRange(q2AnswerIndexes, 1, 4) && (
                            <Text style={styles.li}>
                              • Utilizing a portion of Title I funding for Title
                              I preschool and blending it with TK. Using this
                              strategy, your LEA may be able to cover the cost
                              of instruction for children who are not
                              age-eligible for TK yet. For example, in a
                              classroom with 18 children who are age-eligible
                              for TK and 6 who are not, the LEA may be able to
                              use Title I funding to pay for the slots of
                              children who are not yet eligible for TK. It
                              should be noted that the type of Title I program
                              operated (ie schoolwide, targeted assistance,
                              districtwide, or attendance area) impacts
                              eligibility and prioritization of children for
                              Title I preschool. To learn more about what
                              children are eligible to participate in Title I
                              preschool, visit the{" "}
                              <Link
                                src="https://www2.ed.gov/policy/elsec/guid/preschoolguidance2012.pdf"
                                style={styles.link}
                              >
                                US Department of Education's Guidance Document
                              </Link>
                              .
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(15) && (
                          <Text style={styles.li}>
                            • Braiding TK and CCTR. By braiding TK with CCTR,
                            your LEA can generate more funding than by braiding
                            with programs like ELO-P and ASES. For example, an
                            LEA could operate a 5 hour TK program in the AM and
                            braid it with a 4 hour CCTR program in the PM. It
                            should be noted however that TK children are not age
                            eligible for CCTR until after they turn 5 and that
                            families must meet income and need requirements to
                            qualify for CCTR.
                          </Text>
                        )}
                        {isInRange(q3AnswerIndexes, 6, 10) && (
                          <Text style={styles.li}>
                            • Braiding TK and the LEA’s licensed childcare
                            program(s). By braiding TK with childcare, your LEA
                            can often generate more funding than by braiding
                            with programs like ELO-P and ASES. For example, an
                            LEA could operate a 5 hour TK program in the AM and
                            braid it with the LEA’s childcare program for 4
                            hours in the PM. It should be noted that most
                            childcare programs must meet Title 22 facilities
                            requirements; therefore, the TK and childcare
                            programs likely could not operate in the same
                            classroom unless that facility met both Title 22 and
                            Title 24 and Field Act requirements.
                          </Text>
                        )}
                        {(q3AnswerIndexes.includes(11) ||
                          q3AnswerIndexes.includes(12) ||
                          q3AnswerIndexes.includes(13) ||
                          q3AnswerIndexes.includes(14)) && (
                          <Text style={styles.li}>
                            • Braiding TK and the LEAs afterschool program(s).
                            With the new ELO-P requirement to provide 9 hours of
                            combined instruction and learning opportunities to
                            UPP students, your LEA is likely already braiding TK
                            and the LEA’s afterschool programs. LEAs that have
                            not yet implemented ELO-P are required to do so in
                            the 2023/24 school year. LEAs cannot opt out of the
                            ELO-P requirement.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(5) && (
                            <Text style={styles.li}>
                              • Blend or layer TK with special education
                              preschool. Only 36% of preschool-age students with
                              a disability are currently attending a general
                              education program despite the fact that early
                              education inclusion has been proven to reduce the
                              prevalence of disabilities, improve learning and
                              social emotional development, and reduce special
                              education costs in the long-term. Blending or
                              layering TK and special education preschool can
                              increase staff capacity by consolidating programs
                              in one classroom and is better for children of all
                              abilities. For example, instead of operating a
                              stand-alone special education preschool program
                              with 8 children and 2 adults and a stand-alone TK
                              classroom with 16 kids and 2 adults, an LEA could
                              operate a blended TK/special education preschool
                              with 3 adults and a 1:8 ratio. Because special
                              education preschool may be exempt from Title 22
                              licensing, it is also one of the easiest programs
                              to blend/braid with TK. To learn more about
                              blending and braiding, visit the “Best Practices”
                              folder in the resource hub.
                            </Text>
                          )}
                        {!isInRange(q3AnswerIndexes, 2, 17) && (
                          <Text style={styles.li}>
                            • As an LEA that does not operate any UPK programs
                            outside of TK, you should consider partnering with
                            community-based organization(s) to braid programs.
                            Community-based providers already have the
                            infrastructure, facilities, and staff necessary to
                            operate wrap/extended hour programs and, because
                            most already have contracts with the state or the
                            county administrator of subsidized childcare
                            programs, it is much less administratively
                            burdensome to partner than for the LEA to apply for
                            or stand-up their own program. While partnering with
                            a community-based provider will not reduce your
                            LEA's TK costs, it can increase revenues by making
                            TK significantly more attractive to working parents
                            who need 9+ hours of care. For example, an LEA could
                            provide a 4 hour TK program and then partner with a
                            community-based childcare center that operates both
                            subsidized and fee-based childcare. Parents who need
                            a full day of care could choose to enroll their
                            child in the braided TK and childcare program and,
                            depending on the families income and the
                            availability of a voucher, a portion of the cost
                            would be covered by a state subsidized program. It
                            should be noted for partnerships with
                            community-based providers to be most successful, the
                            wrap hours/program should be provided on the school
                            campus or transportation should be provided from the
                            school site to the community-based provider's
                            facility.
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(9) && (
                    <>
                      <Text style={styles.p}>
                        Pursuant to new ELO-P requirements, LEAs are required to
                        offer and provide 9 hours of free combined instructional
                        time, recess, meals, and expanded learning opportunities
                        to FRPM-eligible TK-6th low-income grade students and
                        foster youth (LEAs must also offer and provide ELO-P to
                        ELL students but are permitted to charge a sliding scale
                        fee to families who are not FRPM eligible); However,
                        many LEAs have noted that the ELO-P and ASES rates are
                        not adequate to cover the actual cost of providing the
                        required expanded learning hours. For LEAs struggling to
                        generate enough revenue to cover the cost of meeting the
                        new expanded learning requirements, consider the
                        following options:
                      </Text>
                      <View style={styles.ul}>
                        {(q2AnswerIndexes.includes(0) ||
                          q2AnswerIndexes.includes(1)) && (
                          <View style={styles.li}>
                            <Text>
                              • As an LEA with lower UPP, your LEA will receive
                              the lower ELO-P rate. To stay fiscally solvent,
                              your LEA should consider partnering with
                              community-based expanded learning or childcare
                              provider to operate your program. Contracting with
                              a community-based provider to operate your ELO-P
                              and/or ASES program is an effective strategy for
                              containing costs and liabilities. While there are
                              pros and cons to contracting with a
                              community-based provider, the primary advantages
                              for those with low UPP rates are:
                            </Text>
                            <List
                              items={[
                                "Community-based expanded learning and childcare providers already have the infrastructure in place to collect family fees. Since most of your students are not eligible for free ELO-P, the program should be collecting fees from families who do not meet the eligibility criteria.",
                                "Because a small percentage of your students are eligible for ELO-P, your LEA may struggle to fill an ELO-P program. A community-based provider can establish an economy of scale that keeps costs down",
                                <Text style={styles.li}>
                                  Contracting reduces an LEA's long-term pension
                                  and benefits liabilities. Important caveat:
                                  New state law requires that a school provide
                                  transportation when a UPP student attending a
                                  school site that is not operating ELO-P
                                  enrolls to attend ELO-P at another school
                                  site. See{" "}
                                  <Link
                                    src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                                    style={styles.link}
                                  >
                                    Education Code 46120(e)(6)
                                  </Link>{" "}
                                  to learn more about this requirement.
                                </Text>,
                              ]}
                              type={ListType.Ordered}
                            />
                          </View>
                        )}
                        {isInRange(q2AnswerIndexes, 1, 4) && (
                          <View style={styles.li}>
                            <Text>
                              • As an LEA with 75%+ UPP, your LEA will receive
                              the higher ELO-P rate; however, it may still not
                              be enough to cover costs for TK students given the
                              lower required ratio of 1:10. Contracting with a
                              community-based provider to operate your ELO-P
                              and/or ASES program is an effective strategy for
                              containing costs and liabilities. While there are
                              pros and cons to contracting with a
                              community-based provider, the primary advantages
                              are:
                            </Text>
                            <View style={styles.ol}>
                              <Text style={styles.li}>
                                1. Community-based expanded learning and
                                childcare providers already have the
                                infrastructure, facilities, and staff necessary
                                to operate ELO-P and ASES programs and can
                                increase their capacity more quickly than most
                                LEAs
                              </Text>
                              <Text style={styles.li}>
                                2. Contracting reduces an LEA's long-term
                                pension and benefits liabilities.
                                <View style={styles.ul}>
                                  <Text style={styles.li}>
                                    • Important caveat: New state law requires
                                    that a school provide transportation when a
                                    UPP student attending a school site that is
                                    not operating ELO-P enrolls to attend ELO-P
                                    at another school site. See{" "}
                                    <Link
                                      src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                                      style={styles.link}
                                    >
                                      Education Code 46120(e)(6)
                                    </Link>{" "}
                                    to learn more about this requirement.
                                  </Text>
                                </View>
                              </Text>
                            </View>
                          </View>
                        )}
                        {q3AnswerIndexes.includes(11) &&
                          q3AnswerIndexes.includes(12) && (
                            <Text style={styles.li}>
                              • Blend ASES and ELO-P. ASES and ELO-P have
                              identical staffing and facilities requirements.
                              The only difference between the programs are their
                              enrollment priorities and ratio requirements for
                              TK-age students. LEAs who have not already done so
                              should consider blending their ASES and ELO-P
                              programs which can help to eliminate duplicative
                              overhead and administrative costs.
                            </Text>
                          )}
                        {q1AnswerIndexes.includes(0) && (
                          <Text style={styles.li}>
                            • Very small LEAs like yours may qualify as a
                            “frontier designated geographic location” which
                            means a school site in an area that has a population
                            density of less than 11 persons per square mile (EC
                            Section 46120[e][2]). ELO-P requirements in frontier
                            locations have been slightly reduced from 9 hours to
                            8 hours of combined instructional and expanded
                            learning time, including recess, meals, per
                            instructional day, and no less than eight hours of
                            expanded learning on at least 30 nonschooldays. To
                            determine whether your LEA meets the definition of a
                            frontier schools, visit{" "}
                            <Link
                              src="https://data-cdegis.opendata.arcgis.com/apps/CDEGIS%3A%3Afrontier-schools-viewer/explore"
                              style={styles.link}
                            >
                              CDE's frontier schools web page
                            </Link>
                            .
                          </Text>
                        )}
                        {(q1AnswerIndexes.includes(0) ||
                          q1AnswerIndexes.includes(1) ||
                          q1AnswerIndexes.includes(2)) && (
                          <Text style={styles.li}>
                            • Consider operating ELO-P/ASES at fewer school
                            sites. Although LEAs are required to provide ELO-P
                            access to all eligible students, they are not
                            required to operate ELO-P at every campus in their
                            district. To address cost concerns, LEAs should
                            consider consolidating ELO-P at fewer school sites.
                            Important caveat: New state law requires that a
                            school provide transportation when a UPP student
                            attending a school site that is not operating ELO-P
                            enrolls to attend ELO-P at another school site. See{" "}
                            <Link
                              src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                              style={styles.link}
                            >
                              Education Code 46120(e)(6)
                            </Link>{" "}
                            to learn more about this requirement.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <View style={styles.li}>
                              <Text>
                                • Blending ELO-P/ASES and CSPP or using only
                                CSPP funding to meet the 9 hour requirement.
                                While ELO-P requires LEAs to offer 9 hours of
                                combined instruction and expanded learning, it
                                does not mandate that schools use ELO-P to fund
                                the additional hours for eligible TK students.
                                An LEA could instead utilize part-day CSPP
                                funding to meet the 9 hour requirement for TK
                                students. CSPP has several advantages over ELO-P
                                or ASES:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The reimbursement rate is significantly
                                  higher. Rates varies by region but can be
                                  $4,000 to $6,000 more per child than ELO-P or
                                  ASES
                                </Text>
                                <Text style={styles.li}>
                                  2. CSPP has a higher income threshold than
                                  ELO-P ($112,105 v. $55,500 for a family of 4)
                                  and thus a larger pool of eligible children
                                  that can help to create an economy of scale
                                </Text>
                                <Text style={styles.li}>
                                  3. CSPP facilities and staff may be more age
                                  appropriate for 4 and 5 year olds than ELO-P
                                  or ASES programs. Alternatively, your LEA
                                  could blend ELO-P and CSPP funding but would
                                  need still need to meet the lower ratio
                                  requirement of 1:8 and the higher staff
                                  qualifications of CSPP.
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(3) && (
                            <View style={styles.li}>
                              <Text>
                                • Blending ELO-P/ASES and Head Start or using
                                only Head Start funding to meet the 9 hour
                                requirement. While ELO-P requires LEAs to offer
                                9 hours of combined instruction and expanded
                                learning, it does not mandate that schools use
                                ELO-P to fund the additional hours for eligible
                                TK students. An LEA could instead utilize Head
                                Start funding to meet the 9 hour requirement for
                                TK students. Head Start has several advantages
                                over ELO-P or ASES:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The reimbursement rate may be higher. Rates
                                  varies by region but can be up to $7,000 more
                                  per child than ELO-P or ASES
                                </Text>
                                <Text style={styles.li}>
                                  2. Head Start programs provide children and
                                  families with many additional services and
                                  supports such as mental health consultation,
                                  parent training, and dental screenings
                                </Text>
                                <Text style={styles.li}>
                                  3. Head Start facilities and staff may be more
                                  age appropriate for 4 and 5 year olds than
                                  ELO-P or ASES programs. Alternatively, your
                                  LEA could blend ELO-P and Head Start funding
                                  but would still need to meet the higher staff
                                  qualifications of Head Start and operate at a
                                  licensed facility.
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(15) && (
                            <View style={styles.li}>
                              <Text>
                                • Blending ELO-P/ASES and CCTR or using only
                                CCTR funding to meet the 9 hour requirement.
                                While ELO-P requires LEAs to offer 9 hours of
                                combined instruction and expanded learning, it
                                does not mandate that schools use ELO-P to fund
                                the additional hours for eligible TK students.
                                An LEA could instead utilize CCTR funding to
                                meet the 9 hour requirement for TK students.
                                CCTR has several advantages over ELO-P or ASES:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The reimbursement rate is significantly
                                  higher. Rates varies by region but can be
                                  $4,000 to $6,000 more per child than ELO-P or
                                  ASES
                                </Text>
                                <Text style={styles.li}>
                                  2. CCTR has a higher income threshold than
                                  ELO-P ($95,283 v. $55,500 for a family of 4
                                  and thus a larger pool of eligible children
                                  that can help to create an economy of scale
                                </Text>
                                <Text style={styles.li}>
                                  3. CCTR facilities and staff may be more age
                                  appropriate for 4 and 5 year olds than ELO-P
                                  or ASES programs. Alternatively, your LEA
                                  could blend ELO-P and CCTR funding but would
                                  need to meet the lower ELO-P ratio requirement
                                  of 1:10, the higher CCTR staff qualifications,
                                  and may need to operate in a licensed
                                  facility.
                                  <View style={styles.ol}>
                                    <Text style={styles.li}>
                                      1. Important caveat: CCTR can only be used
                                      for children who are 5 years and older.
                                      Children under the age of 5 could be
                                      funded through ELO-P or another UPK
                                      program.
                                    </Text>
                                  </View>
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          isInRange(q3AnswerIndexes, 6, 10) && (
                            <View style={styles.li}>
                              <Text>
                                • Blending ELO-P/ASES and the LEA’s licensed
                                childcare program(s). While ELO-P requires LEAs
                                to offer 9 hours of combined instruction and
                                expanded learning, it does not mandate that
                                schools use ELO-P to fund the additional hours
                                for eligible TK students. An LEA could instead
                                utilize childcare funding to meet the 9 hour
                                requirement for TK students. State subsidized
                                childcare programs have several advantages over
                                ELO-P or ASES:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. The reimbursement rate may be higher. Rates
                                  varies by region but can be several thousand
                                  dollars more per child than ELO-P or ASES
                                </Text>
                                <Text style={styles.li}>
                                  2. Subsidized childcare programs have a higher
                                  income threshold than ELO-P ($95,283 v.
                                  $55,500 for a family of 4) and thus a larger
                                  pool of eligible children that can help to
                                  create an economy of scale
                                </Text>
                                <Text style={styles.li}>
                                  3. Childcare facilities and staff may be more
                                  age appropriate for 4 and 5 year olds than
                                  ELO-P or ASES programs. Alternatively, your
                                  LEA could blend ELO-P and childcare funding
                                  but would need to meet the lower ELO-P ratio
                                  requirement of 1:10, the higher childcare
                                  staff qualifications, and may need to operate
                                  at a licensed facility.
                                </Text>
                              </View>
                            </View>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          (q3AnswerIndexes.includes(11) ||
                            q3AnswerIndexes.includes(12)) &&
                          !isInRange(q3AnswerIndexes, 2, 10) &&
                          !isInRange(q3AnswerIndexes, 13, 17) && (
                            <View style={styles.li}>
                              <Text>
                                • While ELO-P requires LEAs to offer 9 hours of
                                combined instruction and expanded learning, it
                                does not mandate that schools use ELO-P to fund
                                the additional hours for eligible TK students.
                                Rather than contracting with a community-based
                                expanded learning provider to offer ELO-P, your
                                LEA could partner with a preschool, Head Start,
                                or childcare provider to meet the 9 hour
                                requirement for TK students. Preschool, Head
                                Start, and childcare programs have several
                                advantages over ELO-P or ASES:
                              </Text>
                              <View style={styles.ol}>
                                <Text style={styles.li}>
                                  1. Reimbursement rates may be higher. Rates
                                  vary by program and region but can be several
                                  thousand dollars more per child than ELO-P or
                                  ASES
                                </Text>
                                <Text style={styles.li}>
                                  2. Most programs have a higher income
                                  threshold than ELO-P (except Head Start) and
                                  thus a larger pool of eligible children that
                                  can help to create an economy of scale
                                </Text>
                                <Text style={styles.li}>
                                  3. Preschool, Head Start, and childcare
                                  facilities and staff may be more age
                                  appropriate for 4 and 5 year olds than ELO-P
                                  or ASES programs. Since there is near 100%
                                  overlap in eligibility between ELO-P/ASES,
                                  CSPP, and CCTR, this approach could cost the
                                  LEA little to nothing if the community-based
                                  provider has enough slots or vouchers to serve
                                  all children from the LEA who are eligible for
                                  ELO-P. It should be noted that the ELO-P
                                  requirement to provide transportation would
                                  likely still apply even if the LEA chooses to
                                  supplement ELO-P for a different UPK funding
                                  source. Therefore, unless the community-based
                                  partner provided on the school campus, the LEA
                                  would likely need to provide transportation
                                  from the school site to the community-based
                                  provider's facility.
                                </Text>
                              </View>
                            </View>
                          )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(10) && (
                    <>
                      <Text style={styles.p}>
                        With increasing wages, COLA, and pension contributions,
                        some LEAs are concerned about the ability to continue
                        operating preschool, Head Start, or childcare programs.
                        Before relinquishing your contract, LEAs should
                        consider:
                      </Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • A study conducted in Southern California
                          demonstrated that parents who enrolled their child at
                          an LEA-operated preschool program were much more
                          likely to enroll their child at that LEAs elementary
                          school when the child became eligible for TK/K. LEAs
                          should consider how relinquishing preschool, Head
                          Start, or childcare programs may contribute to
                          declining enrollment and increase the likelihood that
                          parents will choose to enroll their child in a private
                          or non-traditional K-12 school.
                        </Text>
                        <Text style={styles.li}>
                          • In January 2022, the state implemented rate
                          alignment which had the effect of significantly
                          increasing rates in high-cost regions. For example,
                          preschool and CCTR programs in the bay area saw rates
                          increase by nearly 30% in one year. In addition, the
                          state recently increased adjustment factors for
                          preschool and childcare programs that serve high need
                          children such as students with disabilities, dual
                          language learners, and children under the age of 4.
                          LEAs should review 2022 revenue estimates for their
                          preschool, childcare, and Head Start programs and
                          consider how funding increases will help to right-size
                          their programs.
                        </Text>
                        <View style={styles.li}>
                          <Text>
                            • Partnering with community-based preschool, Head
                            Start, and/or childcare providers. Rather than
                            cutting or ending your contracts, LEAs should
                            consider subcontracting with a community-based
                            provider to operate their program. While there are
                            pros and cons to contracting with a community-based
                            provider, the two primary advantages are:
                          </Text>
                          <View style={styles.ol}>
                            <Text style={styles.li}>
                              1. Community-based providers may already have the
                              infrastructure, facilities, and staff to operate
                              these programs and can generally increase their
                              capacity more quickly than LEAs, and
                            </Text>
                            <Text style={styles.li}>
                              2. Contracting reduces an LEA's long-term pension
                              and benefits liabilities. An LEA can contract with
                              a community-based provider to operate their
                              program while still maintaining the programs
                              location on the school campus.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <Text style={styles.p}>
                        If, after considering the above, your LEA is still
                        concerned that revenues will not exceed costs to operate
                        their preschool, Head Start or childcare program, you
                        should consider blending or braiding programs. To learn
                        more and see illustrations of blending and braiding
                        options, visit the{" "}
                        <Link
                          src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                          style={styles.link}
                        >
                          "Best Practices"
                        </Link>{" "}
                        folder in the resource hub. Given the programs your LEA
                        operates, you should consider:
                      </Text>
                      <View style={styles.ul}>
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <Text style={styles.li}>
                              • Blend or braid TK and CSPP. By blending TK with
                              CSPP, your LEA may be able to reduce costs by
                              consolidating space and staffing and increase
                              revenue by pulling in funding for additional
                              eligible children. For example, in a classroom
                              with 14 children who are age-eligible for TK and
                              10 who are not, the LEA may be able to enroll some
                              of the other 10 children in CSPP if they meet the
                              eligibility requirements for CSPP. Alternatively
                              or additionally, your LEA could braid TK and CSPP.
                              For example, an LEA could operate an 6 hour TK
                              program in the AM and braid it with a 3 hour CSPP
                              program in the PM. It should be noted that CSPP
                              age eligibility is significantly broader than TK
                              (2.9 years to 5 years old) and CSPP income
                              eligibility was recently increased to 100% of SMI
                              (or $112,105 for a family of 4), so many more
                              families now qualify for CSPP. In addition, CSPP
                              enrolled children can generate additional funding
                              through adjustment factors for serving high-need
                              children such as those with exceptional needs or
                              dual language learners.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • Blend or braid TK and Head Start. By blending TK
                              with Head Start, your LEA may be able to reduce
                              costs by consolidating space and staffing and
                              increase revenue by pulling in funding for
                              additional eligible children. For example, in a
                              classroom with 10 children who are age-eligible
                              for TK and 10 who are not, the LEA may be able to
                              enroll some of the other 10 children in Head Start
                              if they meet the eligibility requirements.
                              Alternatively or additionally, your LEA could
                              braid TK and Head Start. For example, an LEA could
                              operate an 5 hour TK program in the AM and braid
                              it with a 4 hour Head Start program in the PM. It
                              should be noted that Head Start income eligibility
                              was recently expanded to include families who are
                              eligible for SNAP benefits (ie approximately
                              $36,075 for a family of 4).
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(15) && (
                            <Text style={styles.li}>
                              • Braid TK and CCTR. By braiding TK with CCTR,
                              your LEA may be able to reduce costs by
                              consolidating space and staffing and increase
                              revenue by pulling in funding for additional
                              eligible children. For example, an LEA could
                              operate a 5 hour TK program in the AM and braid it
                              with a 4 hour CCTR program in the PM. It should be
                              noted however that TK children are not
                              age-eligible for CCTR until after they turn 5 and
                              that families must meet income and need
                              requirements to qualify for CCTR.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          isInRange(q3AnswerIndexes, 6, 10) && (
                            <Text style={styles.li}>
                              • Braid TK and the LEA's licensed childcare
                              program(s). By braiding TK with childcare, your
                              LEA may be able to reduce costs by consolidating
                              space and staffing and increase revenue by pulling
                              in funding for additional eligible children. It
                              should be noted that most childcare programs must
                              meet Title 22 facilities requirements; therefore,
                              the TK and childcare programs likely could not
                              operate in the same classroom unless that facility
                              met both Title 22 and Title 24 and Field Act
                              requirements.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(5) && (
                            <Text style={styles.li}>
                              • Blend or layer TK with special education
                              preschool. Only 36% of preschool-age students with
                              a disability are currently attending a general
                              education program despite the fact that early
                              education inclusion has been proven to reduce the
                              prevalence of disabilities, improve learning and
                              social emotional development, and reduce special
                              education costs in the long-term. Blending or
                              layering TK and special education preschool can
                              increase staff capacity by consolidating programs
                              in one classroom and is better for children of all
                              abilities. For example, instead of operating a
                              stand-alone special education preschool program
                              with 8 children and 2 adults and a stand-alone TK
                              classroom with 16 kids and 2 adults, an LEA could
                              operate a blended TK/special education preschool
                              with 3 adults and a 1:8 ratio. Because special
                              education preschool may be exempt from Title 22
                              licensing, it is also one of the easiest programs
                              to blend/braid with TK. To learn more about
                              blending and braiding, visit the
                              <Link
                                src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                                style={styles.link}
                              >
                                "Best Practices"
                              </Link>
                              folder in the resource hub.{" "}
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(2) &&
                          q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • Blend or braid Head start and CSPP. By braiding
                              Head Start and CSPP, your LEA may be able to
                              reduce costs by consolidating space and staffing
                              and increase revenue by pulling in funding for
                              additional eligible children or extended hours.
                              Head Start and CSPP serve children of the same age
                              and have similar staffing and facilities
                              requirements which makes these programs easier to
                              blend and braid. However, Head Start's low income
                              cut off is significantly harder to meet than
                              CSPP's ($30,000 v. $112,105 for a family of 4. By
                              blending Head Start and CSPP, LEAs can utilize
                              CSPP funding for children who are above the Head
                              Start income threshold and fill classrooms to
                              create economies of scale. For example, if an LEA
                              can only identify 10 children who are eligible for
                              Head Start but another 10 children quality for
                              CSPP, the LEA could blend Head Start and CSPP in
                              one classroom so long as it meets the lower 1:8
                              CSPP ratio and the higher Head Start staffing
                              qualifications. Alternatively, your LEA could
                              braid CSPP and Head Start. For example, an LEA
                              could operate two part-day AM/PM CSPP/Head Start
                              to provide a full-day for a total of 40 pupils.
                              The first class would operate Head Start in the AM
                              and CSPP in the PM; the second class would operate
                              CSPP in the AM and Head Start in the PM.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(2) &&
                          q3AnswerIndexes.includes(15) && (
                            <Text style={styles.li}>
                              • Blend CSPP and CCTR. By blending CCTR and CSPP,
                              your LEA may be able to reduce costs by
                              consolidating space and staffing and increase
                              revenue by pulling in funding for additional
                              eligible children. CCTR and CSPP have similar
                              staffing and facilities requirements which can
                              facilitate blending of these programs; however,
                              blending the programs increases the range of ages
                              that can be served because CCTR is reserved for
                              children above the age of 5 and CSPP is for
                              children ages 2.9 to 5. In addition, CSPP has a
                              slightly higher income cut off than CCTR so
                              blending the programs can increase the number of
                              children eligible and help fill classrooms to
                              create economies of scale. For example, if only 14
                              age-eligible children enroll for CCTR, the LEA
                              could blend the CCTR program with 8 children who
                              are age-eligible for CSPP in one classroom so long
                              as it meets the lower 1:8 CSPP ratio and both
                              programs' requirements.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(15) &&
                          (q3AnswerIndexes.includes(6) ||
                            q3AnswerIndexes.includes(7) ||
                            q3AnswerIndexes.includes(8)) && (
                            <Text style={styles.li}>
                              • Blend CCTR and childcare. By blending CCTR and
                              childcare, your LEA may be able to reduce costs by
                              consolidating space and staffing and increase
                              revenue by pulling in funding for additional
                              eligible children. CCTR and childcare have similar
                              or identical staffing and facilities requirements
                              which can facilitate blending of these programs;
                              however, blending the programs may increase the
                              number of children eligible. For example, if only
                              10 children meet the income and need eligibility
                              requirements of CCTR but another 14 children have
                              AP vouchers for childcare, the LEA could blend
                              CCTR and childcare in one classroom so long as it
                              meets the ratio and program requirements for both
                              programs.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(2) &&
                          (q3AnswerIndexes.includes(6) ||
                            q3AnswerIndexes.includes(7) ||
                            q3AnswerIndexes.includes(8)) && (
                            <Text style={styles.li}>
                              • Braid CSPP and childcare. By braiding childcare
                              and CSPP, your LEA may be able to reduce costs by
                              consolidating space and staffing and increase
                              revenue by pulling in funding for extended hours.
                              Although CSPP and childcare have somewhat
                              different staffing and program requirements, most
                              children that qualify for one program also qualify
                              for the other. Braiding CSPP and childcare can
                              help LEAs pay for an extended day (eg 9+ hours)
                              which makes programs significantly more attractive
                              to the 85% of parents who indicate that they need
                              full day options. For example, an LEA could
                              operate two part-day AM/PM CSPP/childcare classes
                              to provide a full-day for a total of 48 pupils.
                              The first class would operate childcare in the AM
                              and CSPP in the PM; the second class would operate
                              CSPP in the AM and childcare in the PM.
                            </Text>
                          )}
                      </View>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {hasEnrollment && (
            <>
              <View
                style={{
                  ...styles.sectionHeader,
                  ...{ backgroundColor: "#f571b9" },
                }}
              >
                <Text>Enrollment Projections</Text>
              </View>
              {enrollmentOpen && (
                <>
                  {q4AnswerIndexes.includes(15) && (
                    <>
                      <Text style={styles.p}>
                        When TK classrooms are under-enrolled, it can be
                        difficult for revenue to exceed or match costs. LEAs
                        with significantly lower TK enrollment than projected
                        should evaluate how they are communicating with parents
                        about expanded TK eligibility and enrollment
                        opportunities. There are various strategies for
                        communicating with parents, including local radio and
                        billboard campaigns, mailers and distribution of flyers,
                        open house enrollment events, messaging through trusted
                        networks like community-based providers and religious
                        institutions, and communicating with parents of
                        currently enrolled students about enrollment
                        opportunities for younger siblings. For communications
                        resources, flyers, and templates, visit the resource hub
                        folder on{" "}
                        <Link
                          src="https://drive.google.com/drive/folders/1ed3C3FSBWHDjbpqJYZMpPtn-o5hX146N?usp=share_link"
                          style={styles.link}
                        >
                          Communicating with Parents.
                        </Link>
                      </Text>
                      <Text style={styles.p}>
                        In addition, LEAs should consider the following:
                      </Text>
                      <View style={styles.ul}>
                        <View style={styles.li}>
                          <Text>
                            • Evaluate whether your current TK program meets
                            parent needs. Many LEAs operating part-day TK are
                            struggling with under-enrollment because the
                            majority of parents indicate that they need full-day
                            (9+ hours) options for their children. Although TK
                            is free, many middle-class families will forego TK
                            and enroll their child in fee-for-service childcare
                            program that meets parents' needs in order to
                            maintain a job. If your LEA currently operate a TK
                            or TK/afterschool program that provides less than 9
                            hours of instruction and care to most children, you
                            should consider offering a full-day program or
                            sending out a survey gauging interest in accessing
                            full-day options. Full-day care options can be
                            achieved through braiding TK with the LEA's other
                            UPK programs or by partnering with community-based
                            providers who operate UPK programs. To learn more
                            and see illustrations of blending and braiding
                            options, visit the{" "}
                            <Link
                              src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                              style={styles.link}
                            >
                              "Best Practices"
                            </Link>{" "}
                            folder in the resource hub. Based on the programs
                            your LEA operates, you should specifically consider:
                          </Text>
                          <View style={styles.ul}>
                            {q3AnswerIndexes.includes(2) && (
                              <Text style={styles.li}>
                                • Blending or braiding TK and CSPP. By blending
                                TK with CSPP, your LEA can increase enrollment
                                and utilize CSPP funding for children enrolled
                                who are not age-eligible to generate TK funding
                                yet. For example, in a classroom with 14
                                children who are age-eligible for TK and 10 who
                                are income-eligible for CSPP, the LEA could
                                enroll all 24 children in a blended TK/CSPP
                                class. Alternatively or additionally, your LEA
                                could braid TK and CSPP to provide 9 hours of
                                learning and care for the majority of parents
                                who require full-day options. For example, an
                                LEA could operate a 6 hour TK program in the AM
                                and braid it with a 3 hour CSPP program in the
                                PM. It should be noted that CSPP age eligibility
                                is significantly broader than TK (2.9 years to 5
                                years old) and CSPP income eligibility was
                                recently increased to 100% of SMI (or $112,105
                                for a family of 4), so many more families now
                                qualify for CSPP. In addition, CSPP enrolled
                                children can generate additional funding through
                                adjustment factors for serving high-need
                                children such as those with exceptional needs or
                                dual language learners.
                              </Text>
                            )}
                            {q3AnswerIndexes.includes(3) && (
                              <Text style={styles.li}>
                                • Blending or braiding TK and Head Start. By
                                blending with Head Start, your LEA can increase
                                enrollment and generate Head Start funding for
                                children who are not age-eligible to generate TK
                                funding yet. For example, in a classroom with 10
                                children who are age-eligible for TK and 10 who
                                are income-eligible for Head Start, the LEA
                                could enroll all 20 children in a blended
                                TK/Head Start class. Alternatively or
                                additionally, your LEA could braid TK and Head
                                Start to provide 9 hours of learning and care
                                for the majority of parents who require full-day
                                options. For example, an LEA could operate a 5
                                hour TK program in the AM and braid it with a 4
                                hour Head Start program in the PM. It should be
                                noted that Head Start income eligibility was
                                recently expanded to include families who are
                                eligible for SNAP benefits (ie approximately
                                $36,075 for a family of 4).
                              </Text>
                            )}
                            {q3AnswerIndexes.includes(4) && (
                              <Text style={styles.li}>
                                • Blending TK and Title I preschool. By blending
                                TK with Title I preschool, your LEA may be able
                                to cover the cost of instruction for children
                                who are not age-eligible for TK yet. For
                                example, in a classroom with 14 children who are
                                age-eligible for TK and 10 who are not, the LEA
                                may be able to use Title I funding to pay for
                                the slots of children who are not yet eligible
                                for TK.
                              </Text>
                            )}
                            {!q3AnswerIndexes.includes(4) &&
                              (q1AnswerIndexes.includes(1) ||
                                q1AnswerIndexes.includes(2) ||
                                q1AnswerIndexes.includes(3) ||
                                q1AnswerIndexes.includes(4)) && (
                                <Text style={styles.li}>
                                  • Utilizing a portion of Title I funding for
                                  Title I preschool and blending it with TK.
                                  Using this strategy, your LEA may be able to
                                  cover the cost of instruction for children who
                                  are not age-eligible for TK yet. For example,
                                  in a classroom with 18 children who are
                                  age-eligible for TK and 6 who are not, the LEA
                                  may be able to use Title I funding to pay for
                                  the slots of children who are not yet eligible
                                  for TK. It should be noted that the type of
                                  Title I program operated (ie schoolwide,
                                  targeted assistance, districtwide, or
                                  attendance area) impacts eligibility and
                                  prioritization of children for Title I
                                  preschool. To learn more about what children
                                  are eligible to participate in Title I
                                  preschool, visit the{" "}
                                  <Link
                                    src="https://www2.ed.gov/policy/elsec/guid/preschoolguidance2012.pdf"
                                    style={styles.link}
                                  >
                                    US Department of Education's Guidance
                                    Document
                                  </Link>
                                  .
                                </Text>
                              )}
                            {q3AnswerIndexes.includes(15) && (
                              <Text style={styles.li}>
                                • Braiding TK and CCTR. By braiding TK with
                                CCTR, your LEA could offer 9 hours of learning
                                and care for the majority of parents who require
                                full-day options. For example, an LEA could
                                operate a 5 hour TK program in the AM and braid
                                it with a 4 hour CCTR program in the PM. It
                                should be noted however that TK children are not
                                age eligible for CCTR until after they turn 5
                                and that families must meet income and need
                                requirements to qualify for CCTR.
                              </Text>
                            )}
                            {isInRange(q3AnswerIndexes, 6, 10) && (
                              <Text style={styles.li}>
                                • Braiding TK and the LEA's licensed childcare
                                program(s). By braiding TK with childcare, your
                                LEA could offer 9 hours of learning and care for
                                the majority of parents who require full-day
                                options. It should be noted that most childcare
                                programs must meet Title 22 facilities
                                requirements; therefore, braided TK and
                                childcare programs likely could not operate in
                                the same classroom unless that facility met both
                                Title 22 and Title 24 and Field Act
                                requirements.
                              </Text>
                            )}
                            {q2AnswerIndexes.includes(0) &&
                              (q3AnswerIndexes.includes(11) ||
                                q3AnswerIndexes.includes(12) ||
                                q3AnswerIndexes.includes(13) ||
                                q3AnswerIndexes.includes(14)) && (
                                <Text style={styles.li}>
                                  • Braiding TK and the LEAs afterschool
                                  program(s). With the new ELO-P requirement to
                                  provide 9 hours of combined instruction and
                                  learning opportunities to UPP students, your
                                  LEA is likely already braiding TK and the
                                  LEA's afterschool programs; however, given
                                  your LEA's low UPP rate, if you are only
                                  offering afterschool opportunities to UPP
                                  students, you may not be meeting the needs of
                                  the majority of parents who indicate that they
                                  need full-day options. Your LEA may want to
                                  consider expanding your afterschool program to
                                  non-UPP students and charging a sliding scale
                                  family fee to families who are not eligible
                                  for free expanded learning.
                                </Text>
                              )}
                            {!isInRange(q3AnswerIndexes, 2, 17) && (
                              <Text style={styles.li}>
                                • As an LEA that does not operate any UPK
                                programs outside of TK, you should consider
                                partnering with community-based organization(s)
                                to braid programs. Community-based providers
                                already have the infrastructure, facilities, and
                                staff necessary to operate wrap/extended hour
                                programs and, because most already have
                                contracts with the state or the county
                                administrator of subsidized childcare programs,
                                it is much less administratively burdensome to
                                partner than for the LEA to apply for or
                                stand-up their own program. Partnering with
                                community-based providers to offer full-day
                                options can make TK significantly more
                                attractive to working parents who need 9+ hours
                                of care. For example, an LEA could provide a 4
                                hour TK program and then partner with a
                                community-based childcare center that operates
                                both subsidized and fee-based childcare. Parents
                                who need a full day of care could choose to
                                enroll their child in the braided TK and
                                childcare program and, depending on the families
                                income and the availability of a voucher, a
                                portion or all of the cost would be covered by a
                                state subsidized program. It should be noted for
                                partnerships with community-based providers to
                                be most successful, the wrap hours/program
                                should be provided on the school campus or
                                transportation should be provided from the
                                school site to the community-based provider's
                                facility.
                              </Text>
                            )}
                            {q3AnswerIndexes.includes(0) &&
                              !q3AnswerIndexes.includes(1) && (
                                <View style={styles.li}>
                                  <Text>
                                    • LEAs may consider implementing early
                                    admittance TK to help address
                                    under-enrollment. Early admittance TK allows
                                    the LEA to begin receiving a proportionate
                                    share of TK funding once a child who is not
                                    age-eligible for TK turns 5. For example, if
                                    the eligibility cut off for TK is February
                                    2nd and an enrolled child turns 5 on March
                                    2nd, the LEA could receive approximately 20%
                                    of TK ADA for that child, or about $2,000.
                                    When deciding whether or not to admit TK
                                    students early, LEAs should consider:
                                  </Text>
                                  <View style={styles.ol}>
                                    <Text style={styles.li}>
                                      1. Will the cost of operating TK increase
                                      by adding early admittance TK students or
                                      remain the same? In other words, will the
                                      LEA have to add staff and classrooms or
                                      will the cost remain the same while the
                                      LEA is able to collect some additional
                                      funds for the early admittance TK
                                      students?
                                    </Text>
                                    <Text style={styles.li}>
                                      2. How much will the LEA expand TK
                                      eligibility? To contain unfunded costs,
                                      LEAs may want to only expand eligibility
                                      by one or two months beyond what is
                                      statutorily required in that year. For
                                      example, if TK eligibility is September 2
                                      to February 2, the LEA might choose to
                                      only expand eligibility to March 2 instead
                                      of all 4 year olds
                                    </Text>
                                    <View style={styles.li}>
                                      <Text>
                                        3. How will early admittance TK increase
                                        the LEA's ELO-P obligations? ELO-P
                                        requires LEAs to provide 9 hours of
                                        combined instruction and expanded
                                        learning to all enrolled UPP TK
                                        students, whether or not the student is
                                        age-eligible for TK. An LEA that admits
                                        a child to TK early will only receive a
                                        proportion of the TK funding for that
                                        child and will not receive any ELO-P
                                        funding but is still required to offer 9
                                        hours of combined instruction and
                                        expanded learning if the child is UPP
                                        eligible.
                                      </Text>
                                      <View style={styles.ul}>
                                        <Text style={styles.li}>
                                          • To learn more about how early
                                          admittance TK funding is calculated,
                                          visit the{" "}
                                          <Link
                                            src="https://drive.google.com/drive/folders/1uZQKIaqE7EhKbpkhecUIjUncHwgCkTQO?usp=share_link"
                                            style={styles.link}
                                          >
                                            Planning Tools
                                          </Link>
                                          folder in the resource hub. Important
                                          caveat: Because of the way that ADA is
                                          calculated, LEAs are unlikely to
                                          receive any funding for early
                                          admittance TK students who turn 5
                                          after April 15.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              )}
                          </View>
                        </View>
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(16) && (
                    <>
                      <Text style={styles.p}>
                        With TK expansion and the long-term impacts of COVID-19,
                        there are many reasons why reasons preschool, Head
                        Start, and childcare programs are under-enrolled
                        including:
                      </Text>
                      <View style={styles.ul}>
                        <Text style={styles.li}>
                          • Staffing shortages. For LEAs that are unenrolled in
                          preschool, Head Start, or childcare because you have
                          insufficient staff to fill classrooms, please review
                          the tool's suggestions under the "Staffing" section or
                          resubmit your response to Question 4A to indicate that
                          your LEA is experiencing a staffing shortage in
                          preschool, Head Start, or childcare.
                        </Text>
                        <Text style={styles.li}>
                          • 4 year olds enrolling in TK instead of preschool,
                          Head Start, or childcare. For LEAs that are
                          experiencing under-enrollment due to competition with
                          TK, please review the tool’s suggestions under the
                          “Cost” section or resubmit your response to Question
                          4C to indicate that revenue for preschool, Head Start,
                          or childcare is insufficient to cover costs.
                        </Text>
                        <Text style={styles.li}>
                          • Inadequate capacity. For LEAs that are unenrolled in
                          preschool, Head Start, or childcare because you lack
                          facilities space, please review the tool's suggestions
                          under the “Facilities” section or resubmit your
                          response to Question 4B to indicate that your LEA has
                          insufficient preschool, Head Start, or childcare
                          facilities to meet need.
                        </Text>
                        {(q3AnswerIndexes.includes(2) ||
                          q3AnswerIndexes.includes(3) ||
                          q3AnswerIndexes.includes(15) ||
                          q3AnswerIndexes.includes(17) ||
                          q3AnswerIndexes.includes(6) ||
                          q3AnswerIndexes.includes(10)) && (
                          <Text style={styles.li}>
                            • In addition, your LEA may want to consider
                            restructuring your preschool, Head Start, or
                            childcare program to focus on serving 3 year olds.
                            Only about 15% of income-eligible 3 year olds
                            currently have access to a subsidized early learning
                            or childcare program, which makes this group the
                            largest untapped pool of potential enrollees. While
                            there may be additional costs and modifications
                            needed to serve younger children, most of the early
                            learning and care programs offer a higher
                            reimbursement rate for 3 year-olds. CSPP recently
                            implemented a 1.8 adjustment factor for children
                            under 47 months and all childcare programs that
                            receive the regional market rate (RMR) provide
                            higher levels of funding for younger children.
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                  {q4AnswerIndexes.includes(17) && (
                    <>
                      <Text style={styles.p}>
                        Given that ELO-P was launched in just the last few
                        years, it's no surprise that many LEAs are experiencing
                        under-enrollment. LEAs with significantly lower ELO-P
                        enrollment than projected should evaluate how they are
                        communicating with parents about the new expanded
                        learning opportunities and entitlements for UPP
                        students. There are various strategies for communicating
                        with parents, including local radio and billboard
                        campaigns, mailers and distribution of flyers, open
                        house enrollment events, messaging through trusted
                        networks like community-based providers and religious
                        institutions, and including information about expanded
                        learning programs in student enrollment packets. For
                        communications resources, flyers, and templates, visit
                        the resource hub folder on{" "}
                        <Link
                          src="https://drive.google.com/drive/folders/1ed3C3FSBWHDjbpqJYZMpPtn-o5hX146N?usp=share_link"
                          style={styles.link}
                        >
                          Communicating with Parents.
                        </Link>
                      </Text>
                      <Text style={styles.p}>
                        In addition, LEAs should consider blending ELO-P with
                        other UPK programs to increase enrollment and create an
                        economy of scale. To learn more and see illustrations of
                        blending and braiding options, visit the{" "}
                        <Link
                          src="https://drive.google.com/drive/folders/1l0Fa7Wj93Om2_ziF-uto9HOc9jMypVur?usp=share_link"
                          style={styles.link}
                        >
                          Best Practices
                        </Link>{" "}
                        folder in the resource hub. Based on the programs your
                        LEA operates, you should specifically consider:
                      </Text>
                      <View style={styles.ul}>
                        {q3AnswerIndexes.includes(11) &&
                          q3AnswerIndexes.includes(12) && (
                            <Text style={styles.li}>
                              • Blend ASES and ELO-P. ASES and ELO-P have
                              identical staffing and facilities requirements.
                              The only difference between the programs are their
                              enrollment priorities and ratio requirements for
                              TK-age students. To ensure classrooms are fully
                              enrolled, LEAs who have not already done so should
                              consider blending their ASES and ELO-P programs.
                            </Text>
                          )}
                        {(q1AnswerIndexes.includes(0) ||
                          q1AnswerIndexes.includes(1) ||
                          q1AnswerIndexes.includes(2)) && (
                          <Text style={styles.li}>
                            • Consider operating ELO-P/ASES at fewer school
                            sites. Although LEAs are required to provide ELO-P
                            access to all eligible students, they are not
                            required to operate ELO-P at every campus in their
                            district. To address under-enrollment, LEAs should
                            consider consolidating ELO-P at fewer school sites.
                            Important caveat: New state law requires that a
                            school provide transportation when a UPP student
                            attending a school site that is not operating ELO-P
                            enrolls to attend ELO-P at another school site. See{" "}
                            <Link
                              src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                              style={styles.link}
                            >
                              Education Code 46120(e)(6)
                            </Link>{" "}
                            to learn more about this requirement.
                          </Text>
                        )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(2) && (
                            <Text style={styles.li}>
                              • Blending ELO-P/ASES and CSPP. CSPP has a higher
                              income threshold than ELO-P ($112,105 v. $55,500
                              for a family of 4) and thus a larger pool of
                              eligible children that could be enrolled and draw
                              down funding for a blended ELO-P/ASES/CSPP
                              program. It is important to note that LEAs that
                              blend ELO-P and CSPP funding still need to meet
                              the lower ratio requirement of 1:8 and the higher
                              staff qualifications of CSPP.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(3) && (
                            <Text style={styles.li}>
                              • Blending ELO-P/ASES and Head Start. Head Start
                              programs provide children and families with many
                              additional services and supports such as mental
                              health consultation, parent training and coaching,
                              and dental screenings. These supportive wrap
                              services may make a blended ELO-P/ASES/Head Start
                              program more attractive to parents. It is
                              important to note that blended ELO-P/ASES and Head
                              Start programs still need to meet the higher staff
                              qualifications of Head Start and operate at a
                              licensed facility.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          q3AnswerIndexes.includes(15) && (
                            <Text style={styles.li}>
                              • Blending ELO-P/ASES and CCTR. CCTR has a higher
                              income threshold than ELO-P ($95,283 v. $55,500
                              for a family of 4) and thus a larger pool of
                              eligible children that can be enrolled and draw
                              down funding for a blended ELO-P/ASES/CCTR
                              program. It is important to note that ELO-P and
                              CCTR blended programs would need to meet the lower
                              ELO-P ratio requirement of 1:10, the higher CCTR
                              staff qualifications, and may need to operate in a
                              licensed facility. Important caveat: CCTR can only
                              be used for children who are 5 years and older.
                            </Text>
                          )}
                        {q3AnswerIndexes.includes(0) &&
                          isInRange(q3AnswerIndexes, 6, 10) && (
                            <Text style={styles.li}>
                              • Blending ELO-P/ASES and the LEA’s licensed
                              childcare program(s). Most subsidized childcare
                              programs have a higher income threshold than ELO-P
                              ($95,283 v. $55,500 for a family of 4) and thus a
                              larger pool of eligible children that can help to
                              increase enrolled and draw down funding to support
                              a blended program. It should be noted that a
                              blended ELO-P/ASES/childcare program would need to
                              meet the lower ELO-P ratio requirement of 1:10,
                              the higher childcare staff qualifications, and may
                              need to operate at a licensed facility.
                            </Text>
                          )}
                        {!isInRange(q3AnswerIndexes, 2, 10) && (
                          <View style={styles.li}>
                            <Text>
                              • Partnering with community-based expanded
                              learning, after school, or childcare providers to
                              operate your program. Contracting with a
                              community-based provider to operate your ELO-P
                              and/or ASES program may be the best solution for
                              LEAs that do not have enough children generating
                              funds to cover operational costs because it
                              removes the need for the LEA to create an economy
                              of scale. Contracting with LEAs is also an
                              effective strategy for containing costs and
                              liabilities. While there are pros and cons to
                              contracting with a community-based provider, the
                              two primary advantages are:
                            </Text>
                            <View style={styles.ol}>
                              <Text style={styles.li}>
                                1. Community-based afterschool and expanded
                                learning providers already have the
                                infrastructure, facilities, and staff necessary
                                to operate ELO-P and ASES programs and can
                                increase their capacity more quickly than most
                                LEAs.
                              </Text>
                              <Text style={styles.li}>
                                2. Contracting reduces an LEA's long-term
                                pension and benefits liabilities. Important
                                caveat: New state law requires that a school
                                provide transportation when a UPP student
                                attending a school site that is not operating
                                ELO-P enrolls to attend ELO-P at another school
                                site. See
                                <Link
                                  src="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=46120.&lawCode=EDC"
                                  style={styles.link}
                                >
                                  Education Code 46120(e)(6)
                                </Link>{" "}
                                to learn more about this requirement.
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};
export default MyDocument;
