import React from "react";
import { ResourceTopic } from "./ResourceFilter";
import "./ResourceTopicButton.css";

const topicToColorMap: { [key in ResourceTopic]: string } = {
  // Red
  [ResourceTopic.All]: "#335b74",
  [ResourceTopic.UPKWorkshopTools]: "#335b74",
  [ResourceTopic.PlanningTools]: "#1cadff",
  // Blue
  [ResourceTopic.DefiningAndUnderstandingUPK]: "#2582c4",
  [ResourceTopic.CrosswalksLawsAndRegulations]: "#27ced7",
  // Black
  [ResourceTopic.FundingOpportunities]: "#134263",
  [ResourceTopic.FacilitiesAndLearningEnvironments]: "#2e663e",
  [ResourceTopic.BuildingAWorkforcePipeline]: "#3d3d3d",
  // Purple
  [ResourceTopic.ProfessionalDevelopment]: "#61a39f",
  [ResourceTopic.P3Alignment]: "#487b78",
  [ResourceTopic.ImplementingInclusivePrograms]: "#1d9aa1",
  // Orange
  [ResourceTopic.Videos]: "#1d6195",
  // Green
  [ResourceTopic.CollaboratingWithCommunityBasedProviders]: "#3e8853",
  [ResourceTopic.CommunicatingWithParents]: "#42ba98",
};

export interface ResourceTopicButtonProps {
  topic: ResourceTopic;
  onClick: (topic: ResourceTopic) => void;
}

export default function ResourceTopicButton(props: ResourceTopicButtonProps) {
  const { topic, onClick } = props;
  return (
    <div
      className="resource-topic-button-container"
      onClick={() => {
        if (onClick) onClick(topic);
      }}
    >
      <div
        className="resource-topic-button-title"
        style={{ backgroundColor: topicToColorMap[topic] }}
      >
        {topic.toUpperCase()}
      </div>
      <div className="resource-topic-button-gray"></div>
    </div>
  );
}
