import { ResourceTopic } from "../components/resource-library/ResourceFilter";
import workforce from "../images/workforce-dev.png";
import uvk5 from "../images/universal-pk-5.png";
import funding from "../images/funding-sources.png";
import util from "../images/funding-util.png";
import transport from "../images/transport.png";
import overnight from "../images/overnight.png";
import facil from "../images/facil-req.png";
import support from "../images/support-upk.png";
import early from "../images/early.png";
import check from "../images/check.png";
import casbo from "../images/casbo.png";
import vid1 from "../images/vid1.png";

export default [
  {
    title: "Workforce Development Funding Opportunities",
    image: workforce,
    link: "https://docs.google.com/document/d/1oknOvPwSR7PCco4-zW2foemXu7I0qxO5/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.FundingOpportunities,
  },
  {
    title: "Overview of Funding Allocations and Grants",
    image: uvk5,
    link: "https://drive.google.com/file/d/1Z77T2mFopgzr_PMV0KjO5nidj_ExBLi4/view?usp=share_link",
    topic: ResourceTopic.FundingOpportunities,
  },
  {
    title:
      "Funding Sources That Can Be Utilized for Extended Learning and Care",
    image: funding,
    link: "https://docs.google.com/document/d/1eC4mhclghmkW-D0ajoAd6zZhLNJjA6xf/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.FundingOpportunities,
  },
  {
    title: "Funding for Facilities",
    image: util,
    link: "https://docs.google.com/document/d/1b3D2RGkFXNyFY47B75iQuMpMhonDSErN/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.FundingOpportunities,
  },
  {
    title: "Transportation Guidance",
    image: transport,
    link: "https://docs.google.com/document/d/1NJ_cLkkjvVOh8_Xer_JVjQ-Oe1KjBSzB/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "Preschool Facilities Crosswalk: Title 22 and Title 5",
    image: overnight,
    link: "https://docs.google.com/document/d/1XzGU4BZf5rbWs-MHbM_hrJ9gcH7e8F0A/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "LACOE-UPK Learning Environments 4-28-2022",
    image: vid1,
    link: "https://drive.google.com/file/d/1CJseGWdz8YRdvm8ctKSoiAZKkbS6Qy9C/view?usp=share_link",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "Facilities Requirements Planning Considerations and Resources",
    image: facil,
    link: "https://drive.google.com/file/d/1qPPY56LvndCZOHp77OwGb9OTZO_yWb4M/view?usp=share_link",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "Facilities Guidance (UPK Webinar slides)",
    image: support,
    link: "https://drive.google.com/file/d/1t1AfTovqiBRWksdGKoPTV20iVQIXcTTj/view?usp=share_link",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "Early Learning Facilities Guide - 9 2017 (1)",
    image: early,
    link: "https://drive.google.com/file/d/1hr8o2mxhYogwsvt4_wuCsjVsy0eaO1Ne/view?usp=share_link",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "Early Learning Facilities Checklist - 9 2017",
    image: check,
    link: "https://drive.google.com/file/d/1zNd4RdyGCXqo3hJ5zBrsl6I3nxtLqeR6/view?usp=share_link",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
  {
    title: "CASBO - UPK A to Z - Facilities Slides- Adapted for LACOE",
    image: casbo,
    link: "https://drive.google.com/file/d/1UDV0DsV5bOzro5Wq7xQsZxkMG9IJhO7w/view?usp=share_link",
    topic: ResourceTopic.FacilitiesAndLearningEnvironments,
  },
];
