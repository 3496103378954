import React, { useState } from "react";
import "./ResourceLibrary.css";
import ResourceFilter, {
  ResourceCategory,
  ResourceTopic,
} from "../components/resource-library/ResourceFilter";
import ResourceResults from "../components/resource-library/ResourceResults";
import ResourceTopicSelector from "../components/resource-library/ResourceTopicSelector";
import { useNavigate } from "react-router-dom";

export interface ResourceLibraryProps {}

export default function ResourceLibrary(props: ResourceLibraryProps) {
  const navigate = useNavigate();

  const [category, setCategory] = useState<ResourceCategory>(
    ResourceCategory.All
  );

  const [topic, setTopic] = useState<ResourceTopic>(ResourceTopic.All);

  return (
    <div className="resource-lib-container">
      <div
        style={{
          position: "relative",
          display: "flex",
          flex: 1,
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ResourceFilter
            category={category}
            onSelectCategory={setCategory}
            topic={topic}
            onSelectTopic={setTopic}
          />
        </div>
      </div>
      <div className="resource-lib-container-centered">
        <ResourceTopicSelector
          filteredCategory={category}
          filteredTopic={topic}
          onSelectTopic={(topic: ResourceTopic) => {
            console.log("Selected topic: " + topic);
            navigate("/resources/" + topic);
          }}
        />
      </div>
    </div>
  );
}
