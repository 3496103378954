import { useAtom } from "jotai";
import React, { useMemo, useState } from "react";
import {
  question4SectionsAtom,
  questionAnswerIndexesFamily,
  questionAnswerValuesFamily,
  QuestionNumber,
} from "../state/QuestionState";
import { useNavigate } from "react-router-dom";

export default function useAnswerState() {
  const [staffingOpen, setStaffingOpen] = useState(false);
  const [facilitiesOpen, setFacilitiesOpen] = useState(false);
  const [costOpen, setCostOpen] = useState(false);
  const [enrollmentOpen, setEntrollmentOpen] = useState(false);

  const [q1AnswerIndexes, setQ1AnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.One)
  );

  const [q1AnswerValues, setQ1AnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.One)
  );

  const q1Answer = useMemo(() => {
    // maps answer on the form, to what should be displayed in result
    const answerMap = {
      [q1AnswerValues[0]]: "very small",
      [q1AnswerValues[1]]: "small",
      [q1AnswerValues[2]]: "medium",
      [q1AnswerValues[3]]: "large",
    };
    return answerMap[q1AnswerValues[q1AnswerIndexes[0]]];
  }, [q1AnswerIndexes, q1AnswerValues]);

  const [q2AnswerIndexes, setQ2AnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.Two)
  );
  const [q2AnswerValues, setQ2AnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.Two)
  );
  const q2Answer = useMemo(() => {
    const answerMap = {
      [q2AnswerValues[0]]: "low UPP",
      [q2AnswerValues[1]]: "average UPP",
      [q2AnswerValues[2]]: "high UPP",
      [q2AnswerValues[3]]: "very high UPP",
      [q2AnswerValues[4]]: "very high UPP",
    };
    return answerMap[q2AnswerValues[q2AnswerIndexes[0]]];
  }, [q2AnswerIndexes, q2AnswerValues]);

  const [q3AnswerIndexes, setQ3AnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.Three)
  );
  const [q3AnswerValues, setQ3AnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.Three)
  );

  function isInRange(arr: number[], start: number, end: number) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] >= start && arr[i] <= end) {
        return true;
      }
    }
    return false;
  }

  const q3Answer = useMemo(() => {
    const numPrograms = q3AnswerIndexes.length;
    if (numPrograms <= 2) return "2 or fewer UPK programs";
    if (numPrograms >= 3 && numPrograms <= 5) return "several UPK programs";
    if (numPrograms > 5) return "many UPK programs";
  }, [q3AnswerIndexes, q3AnswerValues]);

  const [q4Sections, setQ4Sections] = useAtom(question4SectionsAtom);
  const [q4AnswerIndexes, setQ4AnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.Four)
  );
  const q4Answer = useMemo(() => {
    const titles = q4Sections.reduce((accumulator, current) => {
      if (isInRange(q4AnswerIndexes, current.start, current.end)) {
        const title =
          current.title === "Other"
            ? "other implementation issues"
            : current.title;
        if (!accumulator.includes(title)) accumulator.push(title);
      }
      return accumulator;
    }, [] as string[]);
    if (!titles || titles.length == 0) return "";
    if (titles.length == 1) return titles[0].toLowerCase();
    else if (titles.length == 2) return titles.join(" and ").toLowerCase();
    else {
      const length = titles.length;
      const last = titles[length - 1];
      const first = titles.splice(0, length - 1).join(", ");
      return first.toLowerCase() + " and " + last.toLowerCase();
    }
  }, [q4Sections]);

  const hasStaffing = useMemo(() => {
    return isInRange(q4AnswerIndexes, 0, 3);
  }, [q4AnswerIndexes]);

  const hasFacilities = useMemo(() => {
    return isInRange(q4AnswerIndexes, 4, 7);
  }, [q4AnswerIndexes]);

  const hasCost = useMemo(() => {
    return isInRange(q4AnswerIndexes, 8, 10);
  }, [q4AnswerIndexes]);

  const hasEnrollment = useMemo(() => {
    return isInRange(q4AnswerIndexes, 15, 17);
  }, [q4AnswerIndexes]);

  const hasAtLeastOneSection = useMemo(() => {
    return hasStaffing || hasFacilities || hasCost || hasEnrollment;
  }, [hasStaffing, hasFacilities, hasCost, hasEnrollment]);

  return {
    staffingOpen,
    setStaffingOpen,
    facilitiesOpen,
    setFacilitiesOpen,
    costOpen,
    setCostOpen,
    enrollmentOpen,
    setEntrollmentOpen,
    q1AnswerIndexes,
    q2AnswerIndexes,
    q3AnswerIndexes,
    q4AnswerIndexes,
    q1Answer,
    q2Answer,
    q3Answer,
    q4Answer,
    q4Sections,
    isInRange,
    hasStaffing,
    hasFacilities,
    hasCost,
    hasEnrollment,
    hasAtLeastOneSection,
  };
}
