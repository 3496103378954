import React, { useCallback } from "react";
import "./ResourceFilter.css";

export interface ResourceFilterProps {
  category: ResourceCategory | null;
  onSelectCategory: (category: ResourceCategory) => void;
  topic: ResourceTopic | null;
  onSelectTopic: (topic: ResourceTopic) => void;
}

export enum ResourceCategory {
  All = "All",
  ToolPlanning = "Tools for Planning and Budgeting",
  Definition = "Definitions, Crosswalks, and Laws",
  Communication = "Communication and Collaboration Resources",
  Professional = "Professional Development and Best Practice Resources",
  Videos = "Videos",
  ToolWorkforce = "Tools for Addressing Workforce and Facilities Shortages",
}

export enum ResourceTopic {
  All = "All",
  UPKWorkshopTools = "UPK Workshop Tools",
  PlanningTools = "Planning Tools",
  DefiningAndUnderstandingUPK = "Defining and Understanding UPK",
  CrosswalksLawsAndRegulations = "Crosswalks, Laws, and Regulations",
  FundingOpportunities = "Funding Opportunities",
  ProfessionalDevelopment = "Professional Development",
  P3Alignment = "P-3 Alignment",
  Videos = "Best Practices",
  ImplementingInclusivePrograms = "Implementing Inclusive Programs",
  BuildingAWorkforcePipeline = "Building a Workforce Pipeline",
  FacilitiesAndLearningEnvironments = "Facilities and Learning Environments",
  CommunicatingWithParents = "Communicating with Parents",
  CollaboratingWithCommunityBasedProviders = "Collaborating with Community Based Providers",
}

export default function ResourceFilter(props: ResourceFilterProps) {
  const { category, onSelectCategory, topic, onSelectTopic } = props;

  const onCategorySelect = useCallback(
    (event: React.SyntheticEvent<HTMLSelectElement, Event>) => {
      const c = event.currentTarget.value;
      console.log("Selected Category: ", c);
      onSelectCategory(c as ResourceCategory);
    },
    [onSelectCategory]
  );
  const onTopicSelect = useCallback(
    (event: React.SyntheticEvent<HTMLSelectElement, Event>) => {
      const c = event.currentTarget.value;
      console.log("Selected Topic: ", c);
      onSelectTopic(c as ResourceTopic);
    },
    [onSelectTopic]
  );
  return (
    <div className="resource-filter-main-container">
      <div className="resource-filter-main-container-title">
        UPK Resource Hub
      </div>
      <div className="resource-filter-main-container-subtitle">
        Filter by topic or resource type
      </div>
      <div className="resource-filter-container">
        <div className="resource-filter-selector">
          <div className="resource-filter-selector-label">Topic</div>
          <select
            className="resource-filter-select"
            disabled={category != ResourceCategory.All}
            name="category"
            id="category"
            value={topic as string}
            onChange={onTopicSelect}
          >
            <option>{ResourceTopic.All}</option>
            <option>{ResourceTopic.UPKWorkshopTools}</option>
            <option>{ResourceTopic.PlanningTools}</option>
            <option>{ResourceTopic.DefiningAndUnderstandingUPK}</option>
            <option>{ResourceTopic.CrosswalksLawsAndRegulations}</option>
            <option>{ResourceTopic.FundingOpportunities}</option>
            <option>{ResourceTopic.ProfessionalDevelopment}</option>
            <option>{ResourceTopic.P3Alignment}</option>
            <option>{ResourceTopic.Videos}</option>
            <option>{ResourceTopic.ImplementingInclusivePrograms}</option>
            <option>{ResourceTopic.BuildingAWorkforcePipeline}</option>
            <option>{ResourceTopic.FacilitiesAndLearningEnvironments}</option>
            <option>{ResourceTopic.CommunicatingWithParents}</option>
            <option>
              {ResourceTopic.CollaboratingWithCommunityBasedProviders}
            </option>
          </select>
        </div>

        <div className="resource-filter-or"></div>
        <div className="resource-filter-selector">
          <div className="resource-filter-selector-label">Resource</div>
          <select
            className="resource-filter-select"
            disabled={topic != ResourceTopic.All}
            name="category"
            id="category"
            value={category as string}
            onChange={onCategorySelect}
          >
            <option>{ResourceCategory.All}</option>
            <option>{ResourceCategory.ToolPlanning}</option>
            <option>{ResourceCategory.Definition}</option>
            <option>{ResourceCategory.Communication}</option>
            <option>{ResourceCategory.Professional}</option>
            <option>{ResourceCategory.Videos}</option>
            <option>{ResourceCategory.ToolWorkforce}</option>
          </select>
        </div>
      </div>
    </div>
  );
}
