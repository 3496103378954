import React from "react";
import Document from "../components/misc/Document";
import { PDFViewer } from "@react-pdf/renderer";
import "./Pdf.css";

export interface PdfProps {}
export default function Pdf(props: PdfProps) {
  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document></Document>
    </PDFViewer>
  );
}
