import "./Header.css";
import upkLogo from "../../images/UPK.jpg";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
function Logo() {
  const navigate = useNavigate();
  const onClickLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <div onClick={onClickLogo} className="logo-container">
      <img
        src={upkLogo}
        className="logo"
        alt="UPK Blending, Braiding, and Layering Workshop Logo"
      ></img>
    </div>
  );
}

function Buttons(props: any) {
  const navigate = useNavigate();
  const upkOnClick = useCallback(() => {
    navigate("/resources/Defining%20and%20Understanding%20UPK");
  }, [navigate]);
  return (
    <div className="buttons-container">
      <div className="buttons">
        <div>ABOUT</div>
        <div className="slanted-div"></div>
        <div onClick={upkOnClick}>UPK</div>
        <div className="slanted-div"></div>
        <div>CONTACT</div>
      </div>
    </div>
  );
}

export default function Header() {
  return (
    <div className="header-container">
      <Logo />
      <Buttons />
    </div>
  );
}
