import React, { useCallback, useEffect, useMemo } from "react";
import "./ResourceResults.css";
import { ResourceTopic, ResourceCategory } from "./ResourceFilter";
import { useNavigate, useParams } from "react-router-dom";

import ResourceDocument from "../../constants/ResourceDocument";

import categoryToolPlanningLinks from "../../constants/CategoryToolPlanningLinks";

import categoryDefinitionLinks from "../../constants/CategoryDefinitionsLinks";

import categoryCommunicationLinks from "../../constants/CategoryCommunicationLinks";

import categoryProfessionalLinks from "../../constants/CategoryProfessionalLinks";

import categoryToolWorkforceLinks from "../../constants/CategoryToolWorkforceLinks";

import categoryVideosLinks from "../../constants/CategoryVideosLinks";
export interface ResourceResultsProps {}

const categoryMap = {
  [ResourceCategory.All]: categoryToolPlanningLinks
    .concat(categoryDefinitionLinks)
    .concat(categoryCommunicationLinks)
    .concat(categoryProfessionalLinks)
    .concat(categoryToolWorkforceLinks)
    .concat(categoryVideosLinks),
  [ResourceCategory.ToolPlanning]: categoryToolPlanningLinks,
  [ResourceCategory.Definition]: categoryDefinitionLinks,
  [ResourceCategory.Communication]: categoryCommunicationLinks,
  [ResourceCategory.Professional]: categoryProfessionalLinks,
  [ResourceCategory.ToolWorkforce]: categoryToolWorkforceLinks,
  [ResourceCategory.Videos]: categoryVideosLinks,
};

export default function ResourceResults(props: ResourceResultsProps) {
  const { topic } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("using topic: " + topic);
  }, [topic]);

  const documents = useMemo(() => {
    if (topic != ResourceTopic.All) {
      return categoryMap[ResourceCategory.All].filter(
        (document) => document.topic === topic
      );
    }
    return categoryMap[ResourceCategory.All];
  }, [topic]);

  const renderDocument = useCallback((document: ResourceDocument) => {
    return (
      <div
        className="result-document"
        key={document.title}
        onClick={() => {
          window.open(document.link, "_blank");
        }}
      >
        {document.image && <img src={document.image} height={200} />}
        <div>{document.title}</div>
      </div>
    );
  }, []);

  return (
    <div className="resource-results-container">
      <div
        style={{
          position: "absolute",
          left: 20,
          top: 15,
          color: "blue",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/resources");
        }}
      >
        Back
      </div>
      <h3 style={{ marginTop: 50 }}>{topic}</h3>
      <div className="resource-results-grid">
        {documents && documents.map(renderDocument)}
      </div>
    </div>
  );
}
