import React from "react";
import "./AnswerSectionButton.css";
import chevronRight from "../../images/chev-right.png";

export interface AnswerSectionButtonProps {
  open: boolean;
  color: string;
  title: string;
  onClick: () => void;
}
export default function AnswersSectionButton(props: AnswerSectionButtonProps) {
  const { open = false, color = "#af78e3", title, onClick } = props;
  return (
    <div
      className="answer-section-button-container"
      onClick={onClick}
      style={{ backgroundColor: color }}
    >
      <h2>{title}</h2>
      {!open && <img src={chevronRight} />}
      {open && (
        <img src={chevronRight} style={{ transform: "rotate(90deg)" }} />
      )}
    </div>
  );
}
