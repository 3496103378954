import { Atom, atom } from "jotai";
import { atomFamily, atomWithDefault, atomWithStorage } from "jotai/utils";

export enum QuestionNumber {
  One = 1,
  Two,
  Three,
  Four,
}

export const questionAnswerIndexesFamily = atomFamily(
  (question: QuestionNumber) =>
    atomWithStorage(`question-${question}`, [] as number[])
);

export const questionAnswerValuesFamily = atomFamily(
  (question: QuestionNumber) => {
    if (question == QuestionNumber.One) {
      return atom([
        "40 or fewer TK students",
        "41 to 100 TK students",
        "101 to 500 TK students",
        "501 or more TK students",
        "Our LEA doesn't operate TK",
      ]);
    } else if (question == QuestionNumber.Two) {
      return atom([
        "0% to 55%",
        "55% to 75%",
        "75% to 79%",
        "80% to 89%",
        "90+%",
      ]);
    } else if (question == QuestionNumber.Three) {
      return atom([
        "Transitional Kindergarten",
        "Expanded/Early Transitional Kindergarten",
        "California State Preschool Program (CSPP)",
        "Head Start",
        "Title I Preschool",
        "Special Education Preschool",
        "Title 22 Childcare Center",
        "Title 22 Family Childcare Home (FCCH)",
        "Title 22 Family Childcare Home Network (FCCHN)",
        "Family, Friend and Neighbor (FFN)",
        "Other Locally Funded Childcare Program",
        "Expanded Learning Opportunities Program (ELO-P)",
        "After School Education and Safety Program (ASES)",
        "21st Century Community Learning Centers",
        "Other Locally Funded Expanded Learning Program",
        "General Childcare (CCTR)",
        "Migrant Preschool",
        "Other Locally Funded Early Learning Program",
      ]);
    } else if (question == QuestionNumber.Four) {
      return atom([
        "Not enough TK lead teachers",
        "Not enough TK paraprofessionals, or second adults (to meet the 1:12 ratio)",
        "Not enough ELOP or ASES staff",
        "Not enough preschool or Head Start, or childcare staff",
        "Insufficient TK facilities",
        "Insufficient ELOP or ASES facilities",
        "Insufficient preschool or Head Start facilities",
        "We are considering moving our preschool, childcare, or afterschool program off campus to make room for TK",
        "Revenue for TK is not sufficient to cover operational cost",
        "Revenue for ELOP or ASES is not sufficient to cover operational cost",
        "Revenue for CSPP, Head Start, or childcare is not sufficient to cover operational cost",
        "TK teachers lack experience working with 4 year olds",
        "Administrators of TK programs lack experience administering programs for 4 year olds",
        "Teachers on an emergency credential (PIP or STP) lack curriculum and pedagogy experience",
        "Staff lack experience working with families of 4 year olds",
        "Our TK program is under-enrolled (we projected for more kids than enrolled)",
        "Our CSPP, Head Start, or childcare program is under-enrolled (we have more slots than we can fill)",
        "Our ASES or ELO program is under-enrolled (UPP students are not enrolling in ELOP)",
        "Our childcare program is under-enrolled (we have more slots than we can fill)",
        "We’re uncomfortable implementing a family fee schedule for ELOP (ie we’re uncomfortable charging some families for ELOP but not others)",
        "We’ve never administered a family fee schedule before and are unsure how to collect fees or charge families",
        "We want to implement early learning programs that are more inclusive of children with disabilities",
      ]);
    }
    return atom([] as string[]);
  }
);

export const question4SectionsAtom = atom((get) => {
  const answerValues = get(questionAnswerValuesFamily(QuestionNumber.Four));

  const staffingAnswers = [];
  for (let i = 0; i <= 3; i++) {
    staffingAnswers.push({ index: i, text: answerValues[i] });
  }

  const facilitiesAnswers = [];
  for (let i = 4; i <= 7; i++) {
    facilitiesAnswers.push({ index: i, text: answerValues[i] });
  }

  const costAnswers = [];
  for (let i = 8; i <= 10; i++) {
    costAnswers.push({ index: i, text: answerValues[i] });
  }

  const pdAnswers = [];
  for (let i = 11; i <= 14; i++) {
    pdAnswers.push({ index: i, text: answerValues[i] });
  }

  const enrollmentAnswers = [];
  for (let i = 15; i <= 17; i++) {
    enrollmentAnswers.push({ index: i, text: answerValues[i] });
  }

  const otherAnswers = [];
  for (let i = 20; i <= 22; i++) {
    otherAnswers.push({ index: i, text: answerValues[i] });
  }

  return [
    {
      title: "Staffing",
      start: 0,
      end: 3,
      answers: staffingAnswers,
      color: undefined,
    },
    {
      title: "Facilities",
      start: 4,
      end: 7,
      answers: facilitiesAnswers,
      color: "#789ce3",
    },
    {
      title: "Cost",
      start: 8,
      end: 10,
      answers: costAnswers,
      color: "#a66f5e",
    },
    // {
    //   title: "PD/Expertise",
    //   start: 12,
    //   end: 15,
    //   answers: pdAnswers,
    //   color: "#7ecf72",
    // },
    {
      title: "Enrollment Projections",
      start: 15,
      end: 17,
      answers: enrollmentAnswers,
      color: "#f571b9",
    },
    // {
    //   title: "Other",
    //   start: 20,
    //   end: 22,
    //   answers: otherAnswers,
    //   color: "#6ff0f2",
    // },
  ];
});
