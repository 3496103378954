import { ResourceTopic } from "../components/resource-library/ResourceFilter";

import upkUmbrellaFlyerImage from "../images/upk-umbrella-flyer-image.png";
import overviewOfChildCarImage from "../images/overview-of-child-care.png";
import eceInfrastructureImage from "../images/ece-infrastructure.png";
import upkCrosswalkImage from "../images/upk-crosswalk.png";
import licensingComparison from "../images/licensing-comparison.png";
import incomeEligibilityImage from "../images/income-eligibility.png";
import fullyDayImage from "../images/full-day.png";
import csppGuidanceImage from "../images/cspp-guidance.png";
import csppEligibilityImage from "../images/cspp-eligibility.png";
import budgeProposalEceImage from "../images/budget-proposol-ece.png";
import expandedLearning from "../images/expanded-learning.png";
import earlyAdmittance from "../images/early-admittance-transitional.png";

import ResourceDocument from "./ResourceDocument";

export default [
  {
    title: "UPK Umbrella flyer",
    image: upkUmbrellaFlyerImage,
    link: "https://drive.google.com/file/d/1w4rSc5Behvd_KIN0G20vPRp_HF7Cblhc/view?usp=share_link",
    topic: ResourceTopic.DefiningAndUnderstandingUPK,
  },
  {
    title: "LAO-Overview of Child Care and Preschool Programs_Feb_13_2023",
    image: overviewOfChildCarImage,
    link: "https://drive.google.com/file/d/1UrM0F0e2eDoG4j7TiIuSBiBrjlupYeG8/view?usp=sharing",
    topic: ResourceTopic.DefiningAndUnderstandingUPK,
  },
  {
    title: "ECE Infrastructure",
    image: eceInfrastructureImage,
    link: "https://drive.google.com/file/d/1dIIX-b2EPwXQ49d20vJ1urDdlvF2_r-h/view?usp=share_link",
    topic: ResourceTopic.DefiningAndUnderstandingUPK,
  },
  {
    title: "UPK Crosswalk of Program Standards and Staffing Ratios",
    image: upkCrosswalkImage,
    link: "https://docs.google.com/document/d/1o6Yc89nU2CDWAZbEeKH4YnVBI03Fk3YL/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "Licensing Comparison.",
    image: licensingComparison,
    link: "https://drive.google.com/file/d/1NeKIKcw8MsotL77dHs-pK32ZzAC31jMR/view?usp=sharing",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "Income Eligibility for State and Federal UPK Programs",
    image: incomeEligibilityImage,
    link: "https://docs.google.com/document/d/1FNNZvoyxy1yvrIrhhNBNCf6m2JgEITwj/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "Full-Day and Part-Day Guidance",
    image: fullyDayImage,
    link: "https://docs.google.com/document/d/1NX17UNQWwVXt9do__C6QKCGlMHTB9a-6/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title:
      "Expanded Learning Opportunities Program Guidance and Frequently Asked Questions",
    image: expandedLearning,
    link: "https://docs.google.com/document/d/1bZwKbruzSGrk9elwByUOp9w6X9-KLa2G/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "CSPP Guidance 2022-23",
    image: csppGuidanceImage,
    link: "https://docs.google.com/document/d/1hm3HvFvbt_iPyjVHKLxePn1CkzepSCYC/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "CSPP Eligibillity",
    image: csppEligibilityImage,
    link: "https://drive.google.com/file/d/19_o2jIULYiHh9gjxigo4g-Bowjm0A8iR/view?usp=share_link",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "2022-23-ECE-Budget-Proposal-Summary-1.10.22",
    image: budgeProposalEceImage,
    link: "https://drive.google.com/file/d/1cu-I88CHtcOfAEPHLW1Xz638bay3Ypq1/view?usp=sharing",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
  {
    title: "(ETK) Early Admittance Transitional Kindergarten Information",
    image: earlyAdmittance,
    link: "https://docs.google.com/document/d/1Fp2hl8ew32i_unKxEJPUwTTmG-cgnPkH/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CrosswalksLawsAndRegulations,
  },
] as ResourceDocument[];
