import React, { useMemo } from "react";
import "./ToolLinkButton.css";
import chevron from "../../images/upk-chevron.png";
import greenChevron from "../../images/upk-chevron-green.png";

export interface ToolLinkButtonProps {
  title: string;
  body: string;
  color: "blue" | "green";
  onClick: () => void;
  style?: {};
}

export default function ToolLinkButton(props: any) {
  const {
    title,
    body,
    color: colorType = "blue",
    onClick = () => {},
    style,
  } = props;

  const color = useMemo(() => {
    if (colorType === "green") return "#279ca0";
    else return "#164362";
  }, [colorType]);

  const image = useMemo(() => {
    if (colorType === "green") return greenChevron;
    else return chevron;
  }, [colorType, greenChevron, chevron]);

  return (
    <div
      className="container"
      style={{ ...(style || {}), border: `3px solid ${color}` }}
      onClick={onClick}
    >
      <img src={image} height={100} />
      <div className="text">
        <div className="title" style={{ color }}>
          {title}
        </div>
        <div className="body" style={{ color }}>
          {body}
        </div>
      </div>
    </div>
  );
}
