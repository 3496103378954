import { ResourceTopic } from "../components/resource-library/ResourceFilter";
import supportingSocialImage from "../images/supporting-social-emotional.png";
import powerOfPlayImage from "../images/the-power-of-play.png";
import roleOfPlay from "../images/the-role-of-play.png";
import upkSupportSystem from "../images/upk-support-system.png";
import professionalLearningForLeaders from "../images/professional-learning-for-leaders.png";
import traditionalBestPractices from "../images/transitional-kindergarden-best-practices.png";
import guidance from "../images/guidance-on-pk.png";
import pkthroughthird from "../images/prek-through-third.png";
import upksupport1 from "../images/upk-support-system-1.png";
import stateCenterHome from "../images/state-center-home.png";
import upkSupport5 from "../images/upk-support-5.png";
import earlyMath from "../images/early-math.png";
import systemIntegrate from "../images/system-integrate.png";
import headstart1 from "../images/head-start-1.png";
import guidanceOn5 from "../images/guidance-on-5.png";
import califDeptEdu from "../images/calif-dept-edu.png";
import theAlignmentOf from "../images/the-alignment-of.png";
import universalDesign from "../images/universal-design-5.png";
import supportingChildren from "../images/supporting-children.png";
import implementing from "../images/implementing-inclusive-settings.png";

export default [
  {
    title: "Transitional Kindergarten Best Practices",
    image: traditionalBestPractices,
    link: "https://drive.google.com/file/d/1QGb8OObTLSaM2s2dgzzYapz9tfX2fs5Z/view?usp=share_link",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "Supporting Social Emotional Learning SEL and Development",
    image: supportingSocialImage,
    link: "https://docs.google.com/document/d/10Sd7i1je3zasOwMFB-ubhh4vuV9Gj-qo/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "Powerful Role of Play in Early Education CDE",
    image: roleOfPlay,
    link: "https://drive.google.com/file/d/1TkliUvyYfIWFnPWBzeI5EkUG5hAh4hhL/view?usp=share_link",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "Play-based learning (UPK Webinar slides)",
    image: upkSupportSystem,
    link: "https://drive.google.com/file/d/125fxkILqBSA7bPioXLdUvSXT0aPga-PQ/view?usp=share_link",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "PD Topics for Leaders and Principals",
    image: professionalLearningForLeaders,
    link: "https://docs.google.com/document/d/18vtN_l2Au0c8B8KdytW6yj7URA8wHwZL/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "Guidance for UPK Professional Development for School Leaders",
    image: guidance,
    link: "https://docs.google.com/document/d/1ImJnge4kLloY2lfL67CPR-SNvJCtbaZy/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "Dual Language Learner Support",
    image: pkthroughthird,
    link: "https://docs.google.com/document/d/1f2xgTF_uiLhz4MDDLUHY6YCN5Btcj6eb/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "Assessment Guidance (UPK webinar)",
    image: upksupport1,
    link: "https://drive.google.com/file/d/18XTUp6WZbZqyQAwGjPFVAUGGR35fCwBn/view?usp=share_link",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "21CSLA",
    image: stateCenterHome,
    link: "https://drive.google.com/file/d/1y7i17W4REcApemC9J2-Y2AGVzW4nCB9D/view?usp=share_link",
    topic: ResourceTopic.ProfessionalDevelopment,
  },
  {
    title: "P-3 Alignment (UPK Webinar slides)",
    image: upkSupport5,
    link: "https://drive.google.com/file/d/1tBGAhWn7yF0OxQpvB1IOdG9Hz0FxRVUU/view?usp=share_link",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title: "Math for Early Learners",
    image: earlyMath,
    link: "https://docs.google.com/document/d/107vJGbZHTbSuE2fl93inUC3zRqqxuW4V/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title: "Integrate UPK w_LCAP",
    image: systemIntegrate,
    link: "https://drive.google.com/file/d/1tEThVfGul9_NAey6mwbe78GevtQkc45n/view?usp=share_link",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title: "Head Start Curriculum Review",
    image: headstart1,
    link: "https://docs.google.com/document/d/1bwYWOPumOjhqon23A75cybaHnNbv5id7/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title: "Guidance for UPK Leadership Structure",
    image: guidanceOn5,
    link: "https://docs.google.com/document/d/1tzBpqUiEqwbh4DtqvyNd7QJDLoKUOIL7/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title: "Curriculum and Assessment Guidance",
    image: califDeptEdu,
    link: "https://docs.google.com/document/d/1pLRRa_l1PwUuzC7ZHRTl6JoTucJdra4y/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title:
      "Alignment of Preschool Foundations to State Standards and Head Start Outcomes Framework",
    image: theAlignmentOf,
    link: "https://drive.google.com/file/d/1e1gbYyYBDaWCKpDOL8lRofzSdfGKcmD0/view?usp=share_link",
    topic: ResourceTopic.P3Alignment,
  },
  {
    title: "UDL-Checklist-EC",
    image: universalDesign,
    link: "https://drive.google.com/file/d/13WydEfCqCpqPDYk9bOEMFYk8KlPBKRGe/view?usp=share_link",
    topic: ResourceTopic.ImplementingInclusivePrograms,
  },
  {
    title: "PD Topics for Teachers - Supporting Children with Disabilities",
    image: supportingChildren,
    link: "https://docs.google.com/document/d/1m8vDoz5u7eZz5xxxAtdCi1HBpPdaqf2j/edit?usp=share_link&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.ImplementingInclusivePrograms,
  },
  {
    title: "Implementing Inclusive Settings",
    image: implementing,
    link: "https://docs.google.com/document/d/1A0rLbg5XVutnXDEkXS--vQEI9VzLTcm3OSy2yqkEJJw/edit?usp=share_link",
    topic: ResourceTopic.ImplementingInclusivePrograms,
  },
];
