import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Questions from "./pages/Questions";
import Main from "./pages/Main";
import ResourceLibrary from "./pages/ResourceLibrary";
import ResourceResults from "./components/resource-library/ResourceResults";
import Answers from "./pages/Answers";
import Pdf from "./pages/Pdf";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/questions",
        element: <Questions />,
      },
      {
        path: "/answers",
        element: <Answers />,
      },
      {
        path: "/resources",
        element: <ResourceLibrary />,
      },
      {
        path: "/resources/:topic",
        element: <ResourceResults />,
      },
    ],
  },
  {
    path: "/pdf",
    element: <Pdf />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
