import { ResourceTopic } from "../components/resource-library/ResourceFilter";
import communicatingImage from "../images/communicating-with-parents.png";
import upkFamilyGuide from "../images/upk-family-guide.png";
import tkSpecific from "../images/tk-specific.png";
import resourceReferral from "../images/resource-referral.png";
import opsToEngage from "../images/ops-to-engage.png";
import guidanceOnComms from "../images/guidance-on-comms.png";
import familyGuide from "../images/family-guide.png";
import earlyLearningAge from "../images/early-learning-age.png";
import exportOfChildcare from "../images/export-of-childcare.png";
import identifyingCommunity from "../images/identifying-community.png";
import howToCreate from "../images/how-to-create.png";
import universalPreKinter from "../images/universal-pre-kinter.png";

export default [
  {
    title: "UPK Guide for Families 2022 - Ventura County",
    image: communicatingImage,
    link: "https://drive.google.com/file/d/1TW2msiuMMZ3mQFMyT003U_n-wT5U0GHx/view?usp=sharing",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "UPK Family Guide - Santa Clara County",
    image: upkFamilyGuide,
    link: "https://drive.google.com/file/d/1IWJsNbcLx3qdkmsA-3pkk4VJ3RIniKVP/view?usp=sharing",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "TK Specific Family Sample survey questions draft",
    image: tkSpecific,
    link: "https://docs.google.com/document/d/1oEpz6AGHSOSMj9MV7e7lGcMJ5Rxs9qoN/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "Resource & Referral Flyer for families- Santa Clara County",
    image: resourceReferral,
    link: "https://drive.google.com/file/d/1cu8mg_YLcOLzy-y6xqODWdKedgGvR1Ac/view?usp=sharing",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title:
      "Opportunities to Engage Communities in Universal Prekindergarten Planning.",
    image: opsToEngage,
    link: "https://docs.google.com/document/d/1Vi4RqrzFXkaq3S2R9z-eYjxNdUUaf1vB/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "Guidance on Communicating with Families",
    image: guidanceOnComms,
    link: "https://docs.google.com/presentation/d/11yfeVG8-2A_FZQF2-vbnfNDQMMMORnn646uy0ZiysgI/edit?usp=sharing",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "FamilyGuide_UPK_March2022_FINAL- Butte County",
    image: familyGuide,
    link: "https://drive.google.com/file/d/1nfH2PmOHF76SfrktY6G2U-BiK09RHCPI/view?usp=sharing",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "Early Learning Age-eligibility Calendar",
    image: earlyLearningAge,
    link: "https://docs.google.com/spreadsheets/d/1nu3KB231wG1e0u6d_XqEYrHbc7GbVp-J/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CommunicatingWithParents,
  },
  {
    title: "LEA Context for BBL Workshop [Template]",
    image: exportOfChildcare,
    link: "https://docs.google.com/spreadsheets/d/1baqe0ggO_SriNRbr71yFdxJoIjWJVSch/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CollaboratingWithCommunityBasedProviders,
  },
  {
    title: "Identifying Community Based Partners",
    image: identifyingCommunity,
    link: "https://docs.google.com/presentation/d/1tk4GDkUPZ-G_rzLnXEjLQjhP4JWsbDJ7xHBlL2PUKOQ/edit?usp=sharing",
    topic: ResourceTopic.CollaboratingWithCommunityBasedProviders,
  },
  {
    title: "How to create a provider map for your school district boundaries",
    image: howToCreate,
    link: "https://docs.google.com/document/d/1auw64Vj_Y_CbYbTAsjNs8TGw0i28nkqq4GKkOPudmt8/edit?usp=sharing",
    topic: ResourceTopic.CollaboratingWithCommunityBasedProviders,
  },
  {
    title: "Community-Based Providers UPK Webinar",
    image: universalPreKinter,
    link: "https://docs.google.com/presentation/d/1e1FgT7ONrOyWsBcxYqToCnhFRxE_hNhq/edit?usp=sharing&ouid=104309071880033621264&rtpof=true&sd=true",
    topic: ResourceTopic.CollaboratingWithCommunityBasedProviders,
  },
];
