import { ResourceTopic } from "../components/resource-library/ResourceFilter";
import upksupport200 from "../images/upk-support200.png";
import advantages from "../images/advantages.png";

export default [
  {
    title: "Models of service delivery (COE PPT)",
    image: upksupport200,
    link: "https://drive.google.com/file/d/10ieDJroLRBKivo7UJIN-wSWAEGKRQdEF/view?usp=share_link",
    topic: ResourceTopic.Videos,
  },
  {
    title: "Advantages of Partnering with Head Start",
    image: advantages,
    link: "https://docs.google.com/presentation/d/1Sw2WwzOABTpX1z6yulIDmeg2HO4XCfPQiN7tebIywHI/edit?usp=share_link",
    topic: ResourceTopic.Videos,
  },
];
