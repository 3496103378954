import { useCallback, useEffect, useMemo, useState } from "react";
import "./Questions.css";
import accentImage from "../images/accent-1.png";
import { useAtom } from "jotai";
import {
  Question4BlockData,
  question4BlockDataFamily,
} from "../state/Question4State";
import { useNavigate } from "react-router-dom";
import ToolLinkButton from "../components/misc/ToolLinkButton";
import {
  question4SectionsAtom,
  questionAnswerIndexesFamily,
  questionAnswerValuesFamily,
  QuestionNumber,
} from "../state/QuestionState";

function Question({ text }: { text: string }) {
  return <div className="question">{text}</div>;
}

function Question1() {
  const [answerIndexes, setAnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.One)
  );
  const [answerValues, setAnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.One)
  );

  return (
    <div className="question-container">
      <Question text="1. We are an LEA with" />
      <div className="question-1-answers">
        {answerValues.map((answer, index) => {
          return (
            <div className="q1-radio-option" key={index}>
              <input
                onChange={() => setAnswerIndexes([index])}
                checked={answerIndexes[0] === index}
                type={"radio"}
                value={index}
              />
              {answer}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Question2() {
  const [answerIndexes, setAnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.Two)
  );
  const [answerValues, setAnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.Two)
  );
  return (
    <div className="question-container">
      <Question text="2. The LEA’s unduplicated pupil percentage is:" />
      <div className="question-1-answers">
        {answerValues.map((answer, index) => {
          return (
            <div className="q1-radio-option" key={index}>
              <input
                onChange={() => setAnswerIndexes([index])}
                checked={answerIndexes[0] === index}
                type={"radio"}
                value={index}
              />
              {answer}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Question3() {
  const [answerIndexes, setAnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.Three)
  );

  const [answerValues, setAnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.Three)
  );

  const toggleIndex = useCallback(
    (index: number, checked: boolean) => {
      if (answerIndexes.includes(index) && !checked) {
        // remove
        const i = answerIndexes.indexOf(index);
        answerIndexes.splice(i, 1);
        answerIndexes.sort((a, b) => a - b);
        setAnswerIndexes([...answerIndexes]);
      } else if (!answerIndexes.includes(index) && checked) {
        // add
        answerIndexes.push(index);
        answerIndexes.sort((a, b) => a - b);
        setAnswerIndexes([...answerIndexes]);
      }
    },
    [answerIndexes]
  );

  // Answers at indices 15-18 were added after the original array was created so rather than bumping everything forward and having to make edits to Answers.tsx, we just added to the array at the end
  const earlyLearningAnswers = useMemo(() => {
    const first = answerValues.slice(0, 6);
    const second = answerValues.slice(15, 18);
    const combined = first.concat(second);
    return combined;
  }, [answerValues]);

  return (
    <div className="question-container">
      <Question
        text="3. The LEA currently operates, receives funding for, or holds a contract for (including those it subcontracts) all of the following programs
(mark all that apply)"
      />
      <div className="question-3-answers-container">
        <div className="question-3-column-container">
          <div className="question-3-column">
            <div
              className="question-3-colum-title"
              style={{ backgroundColor: "#315594" }}
            >
              Early Learning Programs
            </div>
            <div className="question-3-answers">
              {earlyLearningAnswers.map((value, index) => {
                let i = index;
                if (index > 5) {
                  i = index + 9;
                }
                return (
                  <div className="question-3-value">
                    <input
                      type="checkbox"
                      onChange={(v) => {
                        toggleIndex(i, v.target.checked);
                      }}
                      checked={answerIndexes.includes(i)}
                    />
                    {value}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="question-3-column">
            <div
              className="question-3-colum-title"
              style={{ backgroundColor: "#5d99d2" }}
            >
              Childcare Programs
            </div>
            <div className="question-3-answers">
              {answerValues.slice(6, 11).map((value, index) => {
                const i = 6 + index;
                return (
                  <div className="question-3-value">
                    <input
                      type="checkbox"
                      onChange={(v) => {
                        toggleIndex(i, v.target.checked);
                      }}
                      checked={answerIndexes.includes(i)}
                    />
                    {value}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="question-3-column">
            <div
              className="question-3-colum-title"
              style={{ backgroundColor: "#6fac4c" }}
            >
              Expanded Learning Programs
            </div>
            <div className="question-3-answers">
              {answerValues.slice(11, 15).map((value, index) => {
                const i = 11 + index;
                return (
                  <div className="question-3-value">
                    <input
                      type="checkbox"
                      onChange={(v) => {
                        toggleIndex(i, v.target.checked);
                      }}
                      checked={answerIndexes.includes(i)}
                    />
                    {value}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function Question4Block({
  title,
  answers,
  titleBackgroundColor,
}: {
  title: string;
  answers: { text: string; index: number }[];
  titleBackgroundColor?: string;
}) {
  const [answerIndexes, setAnswerIndexes] = useAtom(
    questionAnswerIndexesFamily(QuestionNumber.Four)
  );

  const toggleIndex = useCallback(
    (index: number, checked: boolean) => {
      if (answerIndexes.includes(index) && !checked) {
        // remove
        const i = answerIndexes.indexOf(index);
        answerIndexes.splice(i, 1);
        answerIndexes.sort((a, b) => a - b);
        setAnswerIndexes([...answerIndexes]);
      } else if (!answerIndexes.includes(index) && checked) {
        // add
        answerIndexes.push(index);
        answerIndexes.sort((a, b) => a - b);
        setAnswerIndexes([...answerIndexes]);
      }
    },
    [answerIndexes, setAnswerIndexes]
  );

  return (
    <div className="question-4-block-container">
      <div
        style={{ backgroundColor: titleBackgroundColor || "#af78e3" }}
        className="question-4-title"
      >
        {title}
      </div>
      <div className="question-4-answers">
        {answers.map((answer, index) => {
          return (
            <div key={`answer-${index}`} className="question-4-answer">
              <input
                key={`answer-${index}-check`}
                type="checkbox"
                onChange={(event) => {
                  console.log("indexes", answerIndexes);
                  toggleIndex(answer.index, event.target.checked);
                }}
                value={answer.index}
                checked={answerIndexes.includes(answer.index)}
              />
              {answer.text}
            </div>
          );
        })}
      </div>
    </div>
  );
}
function Question4() {
  const [answerValues, setAnswerValues] = useAtom(
    questionAnswerValuesFamily(QuestionNumber.Four)
  );

  const [q4Sections, setQ4Sections] = useAtom(question4SectionsAtom);

  return (
    <div className="question-4-container">
      <Question text="4.  We are experiencing the following issues with implementation (mark all that apply):" />
      <div style={{ height: 15 }}></div>
      <div className="question-1-answers">
        {q4Sections.map((section) => (
          <Question4Block
            title={section.title}
            answers={section.answers}
            titleBackgroundColor={section.color}
          />
        ))}
      </div>
    </div>
  );
}

export default function Questions(props: any) {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate("/answers");
  };

  return (
    <div className="questions-container">
      <img src={accentImage} className="accent-img-1" />
      <Question1 />
      <Question2 />
      <Question3 />
      <Question4 />
      <button className="question-submit-button" onClick={onSubmit}>
        SUBMIT
      </button>
    </div>
  );
}
